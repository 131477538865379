<div class="fondo-negro animated fadeIn" [ngClass]="__modalCrearProyectoService.oculto">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Agregar proyecto</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">


          <section id="icon-tabs">
            <div class="row">
              <div class="col-12">
                <div class="card" style="box-shadow: unset;">
                  <div class="card-content">
                    <div class="card-body">
                      <form class="icons-tab-steps wizard clearfix" role="application" id="steps-uid-0">
                        <div class="steps clearfix">
                          <ul role="tablist">
                            <li role="tab" class="first "
                              [ngClass]="{'current': pasoActual === 1, 'done': pasoActual !== 1}" aria-disabled="false"
                              aria-selected="true">
                              <a>
                                <span class="current-info audible">current step: </span>
                                <span class="step">1</span> Paso 1
                              </a>
                            </li>
                            <li role="tab" [ngClass]="{'current': pasoActual === 2, 'done': pasoActual !== 2}"
                              aria-disabled="false" aria-selected="false">
                              <a>
                                <span class="step">2</span> Paso 2
                              </a>
                            </li>

                          </ul>
                        </div>
                        <div class="content clearfix">
                          <!-- Step 1 -->
                          <h6 id="steps-uid-0-h-0" tabindex="-1" class="title current">Step 1</h6>
                          <fieldset [ngClass]="{'d-block': pasoActual === 1, 'd-none': pasoActual !== 1}"
                            id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" class="body current"
                            aria-hidden="false">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="h3 mt-5">Nombre del proyecto</label>
                                  <input [ngClass]="{'border-danger': error1.estado===1}" [(ngModel)]="nombreProyecto"
                                    type="text" class="form-control form-control-lg" name="nombreProyecto">
                                  <div *ngIf="error1.estado===1" class="help-block">
                                    <ul role="alert">
                                      <li class="text-danger">{{ error1.mensaje }}</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </fieldset>
                          <!-- Step 2 -->
                          <h6 id="steps-uid-0-h-1" tabindex="-1" class="title">Step 2</h6>
                          <fieldset [ngClass]="{'d-block': pasoActual === 2, 'd-none': pasoActual !== 2}"
                            id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" class="body"
                            aria-hidden="true" style="display: none;">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="h3 mt-5">Visión del proyecto</label>
                                  <textarea [(ngModel)]="visionProyecto" rows="6" class="form-control"
                                    name="visionProyecto"></textarea>
                                </div>

                              </div>

                            </div>
                          </fieldset>

                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        <div class="modal-footer">
          <button (click)="regresar()" type="button" class="btn btn-secondary">Regresar</button>
          <button *ngIf="pasoActual<pasoFin" (click)="siguiente()" type="button"
            class="btn btn-primary">Siguiente</button>
          <button *ngIf="pasoActual===pasoFin" (click)="finalizar()" type="button"
            class="btn btn-primary">Finalizar</button>
        </div>
      </div>
    </div>
  </div>



</div>