import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModalCrearProyectoService {

  public oculto="oculto";
  
  constructor(
    public http: HttpClient
  ) { }

  ocultarModal()
   {
    this.oculto="oculto";
    
   }
   
   mostrarModal()
   {
    this.oculto="";
    
   }
}
