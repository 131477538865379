import { Component, DoCheck } from '@angular/core';
import { ModalGestionRolesService } from './modal-gestion-roles.service';
import { CargandoService, UsuarioService, RolesProyectoService } from 'src/app/services/service.index';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-modal-gestion-roles',
  templateUrl: './modal-gestion-roles.component.html',
  styleUrls: ['./modal-gestion-roles.component.css']
})
export class ModalGestionRolesComponent implements DoCheck {

  n_vez = 0;
  eScrumMaster = 0;


  usuarioBuscar = "";
  usuarioRolesData = [];

  dataUsuarioBuscado = [];

  // PARA PAGINACIÓN
  pagination = {
    "totalPaginas": 0,
    "totalData": 0,
    "actual": 1,
    "anterior": false,
    "siguiente": false
  }

  offset = 0;
  limit = 10;


  constructor(
    public _gestionRolesService: ModalGestionRolesService,
    public _usuarioService: UsuarioService,
    public _rolesProyecto: RolesProyectoService,
    public _Cargando: CargandoService,
    public _NotificacionesService: NotificacionesService,
  ) { }

  ngDoCheck() {

    if (this._gestionRolesService.oculto == "" && this.n_vez == 0) {
      this.n_vez = 1;
      this.getRolesEsteProyecto(this._gestionRolesService.idProyecto);
      setTimeout(() => this.existeScrumMaster(), 250);
    }

  }

  cerrarModal() {
    this.n_vez = 0;
    this.eScrumMaster = 0;
    this.usuarioBuscar = "";
    this.usuarioRolesData = [];

    this.dataUsuarioBuscado = [];

    this.offset = 0;
    this.limit = 10;
    this._gestionRolesService.ocultarModal();

    this._rolesProyecto.__refreshItem();
  }

  eliminarUsuarioDelProyecto(usu) {
    let data = {
      "idRolProyecto": usu.idRolProyecto,
      "token": this._usuarioService.token
    }

    this._rolesProyecto.eliminarRolProyecto(data)
      .subscribe((respuesta: any) => {

        this.getRolesEsteProyecto(this._gestionRolesService.idProyecto);
        setTimeout(() => this.existeScrumMaster(), 250);
        setTimeout(() => this.buscarUsuario(""), 500);
      });

  }

  agregarUsuarioAlProyecto(usu) {
    let rol = (<HTMLInputElement>document.getElementById("rolUsu_" + usu.idUsuario)).value;

    let data_enviar = {
      "rol": rol,
      "idUsuario": usu.idUsuario,
      "idProyecto": this._gestionRolesService.idProyecto,
      "token": this._usuarioService.token,
    }

    this._rolesProyecto.agregarRolProyecto(data_enviar)
      .subscribe((respuesta2: any) => {

        this._NotificacionesService.emitirNotificaciones(usu.idUsuario);

        this.getRolesEsteProyecto(this._gestionRolesService.idProyecto);
        setTimeout(() => this.existeScrumMaster(), 250);
        setTimeout(() => this.buscarUsuario(""), 500);
      });

  }

  getRolesEsteProyecto(idProyecto) {
    let data = {
      "idProyecto": idProyecto,
      "token": this._usuarioService.token
    }

    this._Cargando.mostrarAnimacion();
    this._rolesProyecto.buscarPorProyecto(data)
      .subscribe((resp2: any) => {
        this._Cargando.ocultarAnimacion();

        if (resp2.ok) {
          this.usuarioRolesData = resp2.data;
          // console.log(this.usuarioRolesData);
        }
      });
  }

  existeScrumMaster() {
    let dataEnviar = {
      "idProyecto": this._gestionRolesService.idProyecto,
      "token": this._usuarioService.token
    }

    this._rolesProyecto.existeScrumMaster(dataEnviar)
      .subscribe((resp2: any) => {

        if (resp2.ok) {
          this.eScrumMaster = resp2.data[0].cantidad;
          return;
        }
      });
  }

  buscarUsuario(busqueda) {
    let dataEnviar = {
      "offset": this.offset,
      "limit": this.limit,
      "busqueda": busqueda,
      "idProyecto": this._gestionRolesService.idProyecto,
      "token": this._usuarioService.token
    }

    this._usuarioService.getUsersAvailablesForProject(dataEnviar)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.dataUsuarioBuscado = resp2.data;

          this.calcularPaginacion(resp2)
          return;
        }

        this.dataUsuarioBuscado = [];
      });
  }

  cambiar_Pagina(page) {
    // Actualiza la página actual
    this.pagination.actual = page;
    this.offset = (page - 1) * 10;
    // Envia la petición para visualizar la data de esta página
    this.buscarUsuario(this.usuarioBuscar);
  }

  calcularPaginacion(respuesta) {
    this.pagination.totalData = respuesta.cantidadTotal;
    this.pagination.totalPaginas = Math.ceil(respuesta.cantidadTotal / 10);

    if (this.pagination.totalPaginas > this.pagination.actual) {
      this.pagination.siguiente = true;
    } else {
      this.pagination.siguiente = false;
    }

    if (this.pagination.actual > 1) {
      this.pagination.anterior = true;
    } else {
      this.pagination.anterior = false;
    }

  }

}
