<div class="fondo-negro animated fadeIn" [ngClass]="__ModalHistoriaUsuarioService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" style="overflow: auto;height: 660px;">

                <div class="modal-header">
                    <h2 class="modal-title font-weight-bolder">Historia Usuario</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div class="row">

                        <div class="col-md-6 col-12">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="horizontal-form-4">Título</label>
                                <div class="col-md-9">
                                    <input autocomplete="none" [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'"
                                        maxlength="195"
                                        [className]="historiaUsuarioERROR.titulo !== '' ? 'form-control border-danger' : 'form-control'"
                                        type="text" class="form-control square" [(ngModel)]="historiaUsuario.titulo"
                                        name="titulo">
                                    <p class="text-danger">{{historiaUsuarioERROR.titulo}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="horizontal-form-4">Solicitante</label>
                                <div class="col-md-9">
                                    <input autocomplete="none" [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'"
                                        maxlength="195"
                                        [className]="historiaUsuarioERROR.solicitante !== '' ? 'form-control border-danger' : 'form-control'"
                                        type="text" class="form-control square"
                                        [(ngModel)]="historiaUsuario.solicitante" name="solicitante">
                                    <p class="text-danger">{{historiaUsuarioERROR.solicitante}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="horizontal-form-4">Prioridad</label>
                                <div class="col-md-9">
                                    <select [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'" class="form-control"
                                        [(ngModel)]="historiaUsuario.prioridad" name="prioridad">
                                        <option value="ALTA">ALTA</option>
                                        <option value="MEDIA">MEDIA</option>
                                        <option value="BAJA">BAJA</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="horizontal-form-4">Riesgo</label>
                                <div class="col-md-9">
                                    <select [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'" class="form-control"
                                        [(ngModel)]="historiaUsuario.riesgo" name="riesgo">
                                        <option value="ALTA">ALTA</option>
                                        <option value="MEDIA">MEDIA</option>
                                        <option value="BAJA">BAJA</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="horizontal-form-4">Estimación</label>
                                <div class="col-md-9">
                                    <input [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'"
                                        (change)="validarCampo('estimacion')" type="number" min="1" max="100"
                                        class="form-control square" [(ngModel)]="historiaUsuario.estimacion"
                                        name="estimacion">
                                    <p class="text-danger">{{historiaUsuarioERROR.estimacion}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label" for="horizontal-form-4">Estado</label>
                                <div class="col-md-9">
                                    <select [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'" class="form-control"
                                        [(ngModel)]="historiaUsuario.estado" name="estado">
                                        <option value="1">ACTIVO</option>
                                        <option value="0">INACTIVO</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="horizontal-form-4">Descripción</label>
                            <div class="col-md-9">
                                <textarea [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'" maxlength="1495"
                                    [className]="historiaUsuarioERROR.descripcion !== '' ? 'form-control border-danger' : 'form-control'"
                                    rows="4" class="form-control" [(ngModel)]="historiaUsuario.descripcion"
                                    name="descripcion"></textarea>
                                <p class="text-danger">{{historiaUsuarioERROR.descripcion}}</p>
                            </div>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="horizontal-form-4">Condición</label>
                            <div class="col-md-9">
                                <textarea [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'" maxlength="1495" rows="4"
                                    class="form-control" [(ngModel)]="historiaUsuario.condicion"
                                    name="condicion"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label" for="horizontal-form-4">Restricción</label>
                            <div class="col-md-9">
                                <textarea [disabled]="usuarioActual.rol!=='PRODUCT_OWNER'" maxlength="1495" rows="4"
                                    class="form-control" [(ngModel)]="historiaUsuario.restriccion"
                                    name="restriccion"></textarea>
                            </div>
                        </div>
                    </div>


                </div>
                <div *ngIf="usuarioActual.rol==='PRODUCT_OWNER'" class="modal-footer d-block">
                    <!-- <p>fff</p> -->
                    <!-- <ngb-alert role="alert" class="alert alert-danger alert-dismissible">No es posiblle eliminar debido a que se encuentra siendo utilizado.</ngb-alert> -->
                    <div *ngIf="estadoEliminar===0" class="invalid-tooltip ng-star-inserted"
                        style="display:block;width: fit-content;position: relative;top: unset;margin-bottom: 4px;">
                        No es posiblle eliminar debido a que se encuentra siendo utilizado.</div>

                    <button *ngIf="__ModalHistoriaUsuarioService.type==='update'" (click)="eliminarHU()" type="button"
                        class="btn btn-danger float-left">Eliminar <i class="fa fa-trash"
                            aria-hidden="true"></i></button>


                    <button *ngIf="__ModalHistoriaUsuarioService.type==='crear'" (click)="agregarHU()" type="button"
                        class="btn btn-primary float-right m-1">Agregar</button>
                    <button *ngIf="__ModalHistoriaUsuarioService.type==='update'" (click)="actualizarHU()" type="button"
                        class="btn btn-primary float-right m-1">Actualizar</button>

                    <button (click)="cerrarModal()" type="button" class="btn btn-danger float-right m-1">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>