import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import swal from 'sweetalert2';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProyectosService {

  constructor(
    public http: HttpClient
  ) { }

  // @Output() redirectToProject: EventEmitter<boolean> = new EventEmitter();
  // __redirectToProject(idProyecto)
  // {
  //   this.redirectToProject.emit(idProyecto);
  // }

  getVistaIndicadores1(data) {
    let url = URL_SERVICIOS + "proyecto/getVistaIndicadores1?token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  getDataProyecto(data) {
    let url = URL_SERVICIOS + "proyecto/getDataProyecto?idProyecto=" + data.idProyecto + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  buscarProyectos(data) {
    let url = URL_SERVICIOS + "proyecto/getProyectos?offset=" + data.offset + "&limit=" + data.limit + "&busqueda=" + data.busqueda + "&token=" + data.token;

    return this.http.get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");
        return of(err);
      });
  }

  agregarProyecto(data) {
    let url = URL_SERVICIOS + "proyecto/agregarProyecto";

    return this.http.post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch(err => {
        console.log("+++++++++++++++++++++++++");
        console.log(err);
        console.log("+++++++++++++++++++++++++");

        return of(err);
      });
  }

}
