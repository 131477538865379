import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SprintBacklogService {

  constructor(
    public http: HttpClient
  ) {}

  @Output() refreshSprintBacklog2: EventEmitter<boolean> = new EventEmitter();
  __refreshSprintBacklog2()
  {
    this.refreshSprintBacklog2.emit();
  }

  getMaxAndMinDateByProject(data)
  {
    let url=URL_SERVICIOS+ "sprintBacklogItem/getMaxAndMinDateByProject?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  getSprintBacklogItemById(data)
  {
    let url=URL_SERVICIOS+ "sprintBacklogItem/getSprintBacklogItemById?id_SBI="+data.idSprintBacklogItem+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  getSprintBacklogItems(data)
  {
    let url=URL_SERVICIOS+ "sprintBacklog/getSprintBacklogItems?idSprintBacklog="+data.idSprintBacklog+"&idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  getSprintBacklogById(data)
  {
    let url=URL_SERVICIOS+ "sprintBacklog/getSprintBacklogById?idSprintBacklog="+data.idSprintBacklog+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  getSprintBacklog(data)
  {
    let url=URL_SERVICIOS+ "sprintBacklog/getSprintBacklog?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  getAllSprintItemByProyectId(data)
  {
    let url=URL_SERVICIOS+ "sprintBacklogItem/getAllSprintItemByProyectId?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  agregarSB(data)
  {
    
    let url=URL_SERVICIOS+ "sprintBacklog/agregarSB";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }

  marcarFinalizadoSB(data)
  {
    
    let url=URL_SERVICIOS+ "sprintBacklog/marcarFinalizadoSB";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }

  agregarSBI(data)
  {
    
    let url=URL_SERVICIOS+ "sprintBacklogItem/agregarSBI";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }

  eliminarSBI(data_enviar)
  {
    let url = URL_SERVICIOS+"sprintBacklogItem/eliminarSBI";
    
     return this.http.post(url, data_enviar)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }

  eliminarSB(data_enviar)
  {
    let url = URL_SERVICIOS+"sprintBacklog/eliminarSB";
    
     return this.http.post(url, data_enviar)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }


}
