<!-- main menu-->
    <!--.main-menu(class="#{menuColor} #{menuOpenType}", class=(menuShadow == true ? 'menu-shadow' : ''))-->
    <div data-active-color="white" data-background-color="white" class="app-sidebar">
      <!-- main menu header-->
      <!-- Sidebar Header For Starter Kit starts-->
      <div class="sidebar-header">
        <div class="logo clearfix">
            <a class="logo-text float-left">
                <img src="assets/img/portrait/avatars/avatar-08.png" class="rounded-circle img-border gradient-summer" height="35" width="35" alt="Card image">
                <span class="text align-center font-small-2">{{ email }}</span>
            </a>
            <a id="sidebarToggle" href="javascript:;" class="nav-toggle d-none d-sm-none d-md-none d-lg-block" >
                <i data-toggle="expanded" class="ft-toggle-right toggle-icon"></i>
            </a>
            <a id="sidebarClose" href="javascript:;" class="nav-close d-block d-md-block d-lg-none d-xl-none">
                <i class="ft-x"></i>
            </a>
        </div>
      </div>
      <!-- Sidebar Header Ends-->
      <!-- / main menu header-->
      
      <!-- main menu content-->
      <div class="sidebar-content">
        <div class="nav-container">
          <ul id="main-menu-navigation" data-menu="menu-navigation" data-scroll-to-active="true" class="navigation navigation-main">
            <li *ngIf="ROL==='ADMINISTRADOR'" routerLinkActive="active">
                <a [routerLink]="['/dashboard']">
                    <i class="ft-layout"></i>
                    <span data-i18n="" class="menu-title">Dashboard</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="['/proyectos']">
                    <i class="ft-square"></i>
                    <span data-i18n="" class="menu-title">Proyectos</span>
                </a>
            </li>
            <li *ngIf="ROL==='ADMINISTRADOR'" routerLinkActive="active">
                <a [routerLink]="['/usuarios']">
                    <i class="ft-square"></i>
                    <span data-i18n="" class="menu-title">Usuarios</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="['/documentacion']">
                    <i class="ft-square"></i>
                    <span data-i18n="" class="menu-title">Documentación</span>
                </a>
            </li>
            
          </ul>
        </div>
      </div>
      <!-- main menu content-->
      
      <div class="sidebar-background"></div>
      <!-- main menu footer-->
      <!-- include includes/menu-footer-->
      <!-- main menu footer-->
    </div>
    <!-- / main menu-->