import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VistaImagenService {

  public oculto="oculto";

  urlImagen:any;

  constructor(
    public http: HttpClient,
  ) { }

  ocultarModal()
   {
    this.oculto="oculto";
    this.urlImagen="";
   }
   
   mostrarModal(urlImagen)
   {
     this.urlImagen=urlImagen;
     
      this.oculto="";
   }
}
