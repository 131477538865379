<div class="fondo-negro animated fadeIn" [ngClass]="__modalUsuarioService.oculto">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">


          <h2 *ngIf="__modalUsuarioService.opcion==='agregarUsuario'" class="modal-title">Agregar Usuario</h2>
          <h2 *ngIf="__modalUsuarioService.opcion==='updatePassword'" class="modal-title">Actualizar Usuario</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" align="center">

          <div *ngIf="__modalUsuarioService.opcion==='agregarUsuario'" class="card-body">

            <div class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-4">E-mail</label>
              <div class="col-md-9">
                <input [(ngModel)]="USUARIO_DATA.email" autocomplete="none" type="email" id="striped-form-4"
                  class="form-control" name="email2" placeholder="E-mail">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-1">Contraseña</label>
              <div class="col-md-9">
                <input [(ngModel)]="USUARIO_DATA.password" type="password" id="striped-form-1" class="form-control"
                  name="fullname" placeholder="Password">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-1">Rol</label>
              <div class="col-md-9">
                <select [(ngModel)]="USUARIO_DATA.rol" name="users-list-role" class="form-control">
                  <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                  <option value="COLABORADOR">COLABORADOR</option>
                </select>
              </div>
            </div>

          </div>

          <div *ngIf="__modalUsuarioService.opcion==='updatePassword'" class="card-body">

            <div class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-1">Rol</label>
              <div class="col-md-9">
                <select [(ngModel)]="PASSWORD_DATA.role" name="role" class="form-control">
                  <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                  <option value="COLABORADOR">COLABORADOR</option>
                </select>

              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-1">Estado</label>
              <div class="col-md-9">
                <select [(ngModel)]="PASSWORD_DATA.estado" name="estado" class="form-control">
                  <option value="1">HABILITADO</option>
                  <option value="0">DESHABILITADO</option>
                </select>

              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-1">Cambiar contraseña</label>
              <div class="col-md-9">
                <input (click)="changeOption()" type="checkbox" class="form-control">
              </div>
            </div>

            <div *ngIf="optionCambioPass===1" class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-1">Nueva contraseña</label>
              <div class="col-md-9">
                <input [(ngModel)]="PASSWORD_DATA.new_password" type="password" class="form-control" name="fullname"
                  placeholder="Password"
                  style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;">
              </div>
            </div>

            <div *ngIf="optionCambioPass===1" class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-1">Repita la nueva contraseña</label>
              <div class="col-md-9">
                <input [(ngModel)]="PASSWORD_DATA.new_password2" type="password" class="form-control" name="fullname"
                  placeholder="Password"
                  style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;">
              </div>
            </div>


          </div>


        </div>
        <div class="modal-footer">
          <button (click)="cerrarModal()" type="button" class="btn btn-secondary">Close</button>

          <button *ngIf="__modalUsuarioService.opcion==='agregarUsuario'" (click)="agregarUsuario()" type="button"
            id="btn_add_user" class="btn btn-primary">Agregar</button>
          <button *ngIf="__modalUsuarioService.opcion==='updatePassword'" (click)="updatePassword()" type="button"
            id="btn_updatePassword" class="btn btn-primary">Actualizar</button>

        </div>
      </div>
    </div>
  </div>
</div>