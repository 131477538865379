<div class="wrapper">
    <div class="main-panel">
      <!-- BEGIN : Main Content-->
      <div class="main-content" style="padding:0px;">
        <div class="content-wrapper" style="padding:0px;"><!--Forgot Password Starts-->
<section  style="
background-color: #EDA591;
">
<div class="container-fluid forgot-password-bg">
  <div class="row full-height-vh m-0 d-flex align-items-center justify-content-center">
    <div class="col-md-7 col-sm-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body fg-image">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-block text-center py-2">
                <img src="assets/img/gallery/forgot.png" alt="" class="img-fluid" width="300" height="230">
              </div>
              <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                <h4 class="mb-2 card-title">Recuperar contraseña</h4>
                <p class="card-text mb-3">
                  Por favor ingresa el correo de la cuenta que quieres recuperar tu contraseña.
                </p>

                <input type="text" [(ngModel)]="email_recuperar" class="form-control mb-3" placeholder="Email">

                <div class="fg-actions d-flex justify-content-between">
                  <div class="login-btn">
                    <button class="btn btn-outline-primary">
                      <a routerLink="/login" class="text-decoration-none">Regresar al login</a>
                    </button>
                  </div>
                  <div class="recover-pass">
                    <button (click)="recuperar()" class="btn btn-primary">
                      Recuperar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
<!--Forgot Password Ends-->

        </div>
      </div>
      <!-- END : End Main Content-->
    </div>
  </div>