import { Component, DoCheck } from '@angular/core';
import { VistaImagenService } from './vista-imagen.service';

@Component({
  selector: 'app-vista-imagen',
  templateUrl: './vista-imagen.component.html'
})
export class VistaImagenComponent implements DoCheck {

  constructor(
    public _VistaImagenService: VistaImagenService,
  ) { }

  ngDoCheck() {

    if (this._VistaImagenService.oculto == "" && this._VistaImagenService.urlImagen != "") {

    }
  }

}
