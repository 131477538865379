<div *ngIf="modo==='BY_PROJECT_WITH_MODAL'">
    
    <div class="card card-outline-secondary text-center">
        <div class="card-content">
          <div class="card-body pt-3">
            <div class="row">
                <div *ngFor="let hu of historiasDeUsuarios" class="col-lg-3 col-md-4 col-12">
                    <button (click)="modalUpdateHU(hu)" style="border: 0px;background-color: #fff;">
                        <img src="assets/img/icons/historiaUsuario.png" alt="" class="d-block m-auto w-50">
                        <h5 class="text-center mt-2">{{ hu.titulo }}</h5>
                    </button>
                    
                    
                </div>
                
                
            </div>
          </div>
        </div>
      </div>
</div>

<div *ngIf="modo==='BY_PROJECT'">
    
    <div class="card card-outline-secondary text-center">
        <div class="card-content">
          <div class="card-body pt-3">
            <div class="row">
                <div *ngFor="let hu of historiasDeUsuarios" class="col-lg-3 col-md-4 col-12">
                    <button (click)="emitirEstablecer(hu)" id="btnEscogerHistoriaUsuario_{{hu.id}}" style="border: 0px;background-color: #fff;">
                        <img src="assets/img/icons/historiaUsuario.png" alt="" class="d-block m-auto w-50">
                        <h5 class="text-center mt-2">{{ hu.titulo }}</h5>
                    </button>
                    
                    
                </div>
                
                
            </div>
          </div>
        </div>
      </div>
</div>

<div *ngIf="modo==='DISPLAY_FORM_HU'">

    <div *ngIf="estado" class="row w-100">
        
        <div class="col-md-6 col-12">
        <div class="form-group row">
            <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Título</label>
            <div class="col-md-9">
                <p class="inputStyle1">{{jsonHU.titulo}}</p>
            </div>
        </div>
        </div>

        <div class="col-md-6 col-12">
        <div class="form-group row">
            <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Solicitante</label>
            <div class="col-md-9">
                <p class="inputStyle1">{{jsonHU.solicitante}}</p>
            </div>
        </div>
        </div>

        <div class="col-md-6 col-12">
        <div class="form-group row">
            <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Prioridad</label>
            <div class="col-md-9">
                <p class="inputStyle1">{{jsonHU.prioridad}}</p>
            </div>
        </div>
        </div>
        
        <div class="col-md-6 col-12">
        <div class="form-group row">
            <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Riesgo</label>
            <div class="col-md-9">
                <p class="inputStyle1">{{jsonHU.riesgo}}</p>
            </div>
        </div>
        </div>

        <div class="col-md-6 col-12">
            <div class="form-group row">
                <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Estimacion</label>
                <div class="col-md-9">
                    <p class="inputStyle1">{{jsonHU.estimacion}}</p>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-12">
            <div class="form-group row">
                <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Estado</label>
                <div class="col-md-9">
                    <p class="inputStyle1">{{jsonHU.estado}}</p>
                </div>
            </div>
        </div>
        
        <div class="col-12">
        <div class="form-group row">
            <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Descripcion</label>
            <div class="col-md-9">
                <textarea disabled class="inputStyle1 w-100" style="min-height: 135px;">{{jsonHU.descripcion}}</textarea>
                <!-- <p class="inputStyle1">{{jsonHU.descripcion}}</p> -->
            </div>
        </div>
        </div>

        <div class="col-12">
        <div class="form-group row">
            <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Condicion</label>
            <div class="col-md-9">
                <p class="inputStyle1">{{jsonHU.condicion}}</p>
            </div>
        </div>
        </div>

        <div class="col-12">
        <div class="form-group row">
            <label class="col-md-3 col-form-label font-weight-bolder" for="horizontal-form-4">Restriccion</label>
            <div class="col-md-9">
                <p class="inputStyle1">{{jsonHU.restriccion}}</p>
            </div>
        </div>
        </div>
    </div>




</div>