<div class="wrapper">
    <div class="main-panel">
      <!-- BEGIN : Main Content-->
      <div class="main-content" style="padding:0px;">
        <div class="content-wrapper" style="padding:0px;"><!--Registration Page Starts-->
<section id="" style="
background-color: #EFD8AC;
">
<div class="container-fluid">
  <div class="row full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card">
        <div class="card-content">
          <div class="card-body register-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-block py-2 text-center">
                <img src="assets/img/gallery/register.png" alt="" class="img-fluid mt-3 pl-3" width="400"
                  height="230">
              </div>

                <div class="col-lg-6 col-md-12 px-4 pt-3 bg-white">
                    <h4 class="card-title mb-2">Crea tu cuenta</h4>
                    <p class="card-text mb-3">
                    Completa el siguiente formulario para crear tu cuenta.
                    </p>
                    

                    <form ngNativeValidate [formGroup]="forma" (ngSubmit)="registrarUsuario()" id="loginform" action="index.html">
                        
                        <input formControlName="correo" name="correo" type="email" class="form-control mb-3" placeholder="Correo electrónico" required />
                        <input formControlName="password" name="password" type="password" class="form-control mb-3" placeholder="Clave" required />
                        <input formControlName="confirm_password" name="confirm_password" type="password" class="form-control mb-3" placeholder="Confirmar clave" required />

                        <div class="custom-control custom-checkbox custom-control-inline mb-3">
                            <input formControlName="condiciones" name="condiciones" id="checkbox-signup" type="checkbox">
                            <label for="checkbox-signup" style="margin-left: 5px;"> Acepto todos los  <a href="#">Terminos</a></label>
                        </div>

                        <div class="fg-actions d-flex justify-content-between">
                        <div class="login-btn">
                            <button type="button" class="btn btn-outline-primary">
                              <a routerLink="/login" class="text-decoration-none">
                                  Regresar al login
                              </a>
                            </button>
                        </div>
                        <div class="recover-pass">
                            <button class="btn btn-primary text-white" type="submit">Registrame</button>
                        </div>
                        </div>
                    </form>
                    

                </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
<!--Registration Page Ends-->

        </div>
      </div>
      <!-- END : End Main Content-->
    </div>
  </div>