import { Component, DoCheck } from '@angular/core';
import { ModalCrearProyectoService } from './modal-crear-proyecto.service';
import { ProyectosService } from 'src/app/services/proyectos/proyectos.service';
import { UsuarioService } from 'src/app/services/service.index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-crear-proyecto',
  templateUrl: './modal-crear-proyecto.component.html',
})
export class ModalCrearProyectoComponent implements DoCheck {
  n_vez = 0;

  nombreProyecto = "";
  visionProyecto = "";

  pasoActual = 1;
  pasoFin = 2;

  error1 = {
    "estado": 0,
    "mensaje": "Tienes que ingresar un nombre para el proyecto"
  }

  constructor(
    public __modalCrearProyectoService: ModalCrearProyectoService,
    public __modalProyectosService: ProyectosService,
    public router: Router,
    public _usuarioService: UsuarioService
  ) { }

  ngDoCheck() {

    if (this.__modalCrearProyectoService.oculto == "" && this.n_vez == 0) {
      this.n_vez = 1;
    }

  }

  regresar() {
    if (this.pasoActual > 1) {
      this.pasoActual--;
    }
  }

  siguiente() {
    if (this.pasoActual < this.pasoFin) {
      if (this.pasoActual == 1 && !this.nombreProyecto) {
        this.error1.estado = 1;
        return;
      } else {
        this.error1.estado = 0;
        this.pasoActual++;
      }

    }
  }

  cerrarModal() {
    this.n_vez = 0;
    this.nombreProyecto = "";
    this.visionProyecto = "";
    this.pasoActual = 1;

    // this.ProductSet=null;

    this.__modalCrearProyectoService.ocultarModal();
  }

  finalizar() {

    let data_enviar_x = {
      "token": this._usuarioService.token,
      "nombreProyecto": this.nombreProyecto,
      "visionProyecto": this.visionProyecto
    }

    this.__modalProyectosService.agregarProyecto(data_enviar_x)
      .subscribe((respuesta: any) => {
        this.cerrarModal();
        this.router.navigate(["/proyecto/", respuesta.data]);


      });

  }

}
