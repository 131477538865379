import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalHistoriaUsuarioService {

  public oculto="oculto";
  public idProyecto=0;
  public hu={
    "id":"",
    "titulo":"",
    "solicitante":"",
    "prioridad":"",
    "riesgo":"",
    "estimacion":"",
    "orden":"",
    "estado":"",
    "descripcion":"",
    "condicion":"",
    "restriccion":"",
  };

  public type="";

  constructor() { }


  ocultarModal()
   {
    this.oculto="oculto";

    this.type="";
    this.idProyecto=0;
    this.hu={
      "id":"",
      "titulo":"",
      "solicitante":"",
      "prioridad":"",
      "riesgo":"",
      "estimacion":"",
      "orden":"",
      "estado":"",
      "descripcion":"",
      "condicion":"",
      "restriccion":"",
    };
   }

   mostrarModal(type="",idProyecto=0,hu={
    "id":"",
    "titulo":"",
    "solicitante":"",
    "prioridad":"",
    "riesgo":"",
    "estimacion":"",
    "orden":"",
    "estado":"",
    "descripcion":"",
    "condicion":"",
    "restriccion":"",
  })
   {
    this.oculto="";
    this.idProyecto=idProyecto;
    this.type=type;
    this.hu=hu;

    
   }
}
