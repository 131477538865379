<!--Login Page Starts-->
<div class="row full-height-vh m-0">
  <img class="wave" src="assets/img/myImages/wave.png" style="position: fixed;bottom: 0;right: 0;height: 100%;">
  <div class="col-12 d-flex align-items-center justify-content-center">
    <div class="card" style="background: rgba(227,150,90,0.3);">
      <div class="card-body login-img" style="background: unset;">
        <div class="row m-0">
          <!-- <div class="col-lg-6 d-lg-block d-none py-2 text-center align-middle">
            <img src="assets/img/myImages/logo.png" alt="" class="img-fluid mt-5" width="400" height="230">
          </div> -->
          <div class=" col-md-12 bg-white px-4 pt-3">
            <h1 class="mb-3 text-center text-bold-600">Scrum process manager</h1>
            <h4 class="mb-2 card-title">Iniciar Sesión</h4>

            <form ngNativeValidate #f="ngForm" (ngSubmit)="ingresar(f)">
              <input [ngModel]="email" name="email" class="form-control mb-3" type="email" autocomplete="none" required
                placeholder="E-mail">
              <input ngModel name="password" class="form-control mb-1" type="password" required
                placeholder="Contraseña">


              <div class="d-flex justify-content-between mt-2 mb-3">
                <!-- <div class="forgot-password-option">
                    <a routerLink="/recuperarClave" class="text-decoration-none text-primary">Olvidaste tu contraseña ?</a>
                  </div> -->
              </div>

              <div class="fg-actions d-flex justify-content-between">
                <div class="login-btn">
                  <!-- <button routerLink="/register" type="button" class="btn btn-outline-primary btn-lg">
                      <a class="text-decoration-none">Register</a>
                    </button> -->
                </div>

                <div class="recover-pass">
                  <button class="btn btn-primary btn-lg" type="submit">Iniciar sesión</button>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Login Page Ends-->