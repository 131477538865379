import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoriaUsuarioService {

  constructor(
    public http: HttpClient
  ) {}

  @Output() refreshHistoriaUsuario: EventEmitter<boolean> = new EventEmitter();
  __refreshHistoriaUsuario()
  {
    this.refreshHistoriaUsuario.emit();
  }

  eliminarHU(data)
  {
    let url=URL_SERVICIOS+ "historiaUsuario/eliminarHU";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                      
                    });
  }

  agregarHU(data)
  {
    
    let url=URL_SERVICIOS+ "historiaUsuario/agregarHU";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }

  actualizarHU(data)
  {
    
    let url=URL_SERVICIOS+ "historiaUsuario/actualizarHU";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }

  getHistoriaUsuario(data)
  {
    let url=URL_SERVICIOS+ "historiaUsuario/getHistoriaUsuario?offset="+data.offset+"&limit="+data.limit+"&idProyecto="+data.idProyecto+"&busqueda="+data.busqueda+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  getProductsBacklogOfHU(data)
  {
    let url=URL_SERVICIOS+ "historiaUsuario/getProductsBacklogOfHU?idHistoriaUsuario="+data.idHistoriaUsuario+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  getHuInPbByProjectId(data)
  {
    let url=URL_SERVICIOS+ "historiaUsuario/getHuInPbByProjectId?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  getQuantityHistoriaUsuarioByProject(data)
  {
    let url=URL_SERVICIOS+ "historiaUsuario/getQuantityHistoriaUsuarioByProject?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

}
