import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';


import { CommonModule } from '@angular/common';

import { 
  UsuarioService,
  CargandoService,
  ProductBacklogService,
  HistoriaUsuarioService,
  RolesProyectoService
 } from './service.index';
 
  
  

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers:[
    UsuarioService,
    CargandoService,
    ProductBacklogService,
    RolesProyectoService,
    HistoriaUsuarioService,
  ]
})
export class ServiceModule { }
