import { Component, DoCheck } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import { WebSocketServerService } from 'src/app/services/web-socket-server.service';
import { ModalComentariosService } from './modal-comentarios.service';

@Component({
  selector: 'app-modal-comentarios',
  templateUrl: './modal-comentarios.component.html'
})
export class ModalComentariosComponent implements DoCheck {

  n_vez = 0

  usuarioActual = { "id": 0 };

  myCommentForSend = ""

  comments = [];

  constructor(
    public _ModalComentariosService: ModalComentariosService,
    public _usuarioService: UsuarioService,
    public wsService: WebSocketServerService,
  ) { }

  ngDoCheck() {

    if (this._ModalComentariosService.oculto == "" && this.n_vez == 0 && this._ModalComentariosService.idEntregable != 0 && this._ModalComentariosService.idProyecto != 0) {
      this.getDataUsuario();
      setTimeout(() => this.getComentariosEntregableByESBI(), 200);

      // this.wsService.loginWS(this._ModalComentariosService.idProyecto,"comentariosESBI").subscribe();


      this.wsService.listen('emitirConsultaComentariosESBI').subscribe((msg: any) => {
        this.getComentariosEntregableByESBI()
        setTimeout(() => this.updateScroll(), 200)
      });

      this.n_vez = 1;

    }
  }


  emitirConsultaComentariosESBI() {
    let dataEnviar = {
      "token": this._usuarioService.token,
      "idProyecto": this._ModalComentariosService.idProyecto,
    }

    this.wsService.emit('emitirConsultaComentariosESBI', dataEnviar);
  }


  getComentariosEntregableByESBI() {
    let data = {
      "idESBI": this._ModalComentariosService.idEntregable,
      "token": this._usuarioService.token
    }

    this._ModalComentariosService.getComentariosEntregableByESBI(data)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.comments = resp2.data;
        }
      });
  }

  sendComment() {
    if (this.myCommentForSend.trim() == "") {
      console.log("ingrese palabra")
      return;
    }

    let data2 = {
      "idEntregableSprint": this._ModalComentariosService.idEntregable,
      "token": this._usuarioService.token,
      "myCommentForSend": this.myCommentForSend,
    }

    this._ModalComentariosService.sendComment(data2)
      .subscribe((resp3: any) => {
        this.emitirConsultaComentariosESBI()
      });
    this.myCommentForSend = ""
  }

  cerrarModal() {
    this.n_vez = 0;
    this.usuarioActual = { "id": 0 };

    this.myCommentForSend = ""

    this.comments = [];

    this._ModalComentariosService.ocultarModal();
  }

  getDataUsuario() {
    this._usuarioService.obtenerDataToken(this._usuarioService.token)
      .subscribe((dUsu: any) => {
        this.usuarioActual = dUsu["usuario"];
      });
  }


  updateScroll() {
    document.getElementById("cajaChat1").scrollTop = document.getElementById("cajaChat1").scrollHeight
  }

}
