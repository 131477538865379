import { Component, DoCheck, OnInit } from '@angular/core';

import { timer } from 'rxjs';
import { VistaCuentaRegresivaService } from './vista-cuenta-regresiva.service';

@Component({
  selector: 'app-vista-cuenta-regresiva',
  templateUrl: './vista-cuenta-regresiva.component.html',
  styleUrls: ['./vista-cuenta-regresiva.component.css']
})
export class VistaCuentaRegresivaComponent implements OnInit, DoCheck {

  n_vez = 0

  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);
  clock: any;

  constructor(
    public __VistaCuentaRegresivaService: VistaCuentaRegresivaService,
  ) { }

  ngOnInit() {

    this.__VistaCuentaRegresivaService.cerrarCuentaRegresiva.subscribe(dataSubscribe => {
      this.cerrarModal()
    });

  }

  ngDoCheck() {

    if (this.__VistaCuentaRegresivaService.oculto == "" && this.n_vez == 0) {
      this.n_vez = 1;
      this.iniciar(this.__VistaCuentaRegresivaService.fechaFin);
    }
  }

  iniciar(fechaFin) {
    this.clock = this.source.subscribe(t => {
      this.now = new Date();
      this.end = new Date(fechaFin);

      this.showDate();
    });
  }


  showDate() {
    let distance = this.end - this.now;
    if (distance > 0) {
      this.day = Math.floor(distance / this._day);
      this.hours = Math.floor((distance % this._day) / this._hour);
      this.minutes = Math.floor((distance % this._hour) / this._minute);
      this.seconds = Math.floor((distance % this._minute) / this._second);
    } else {
      this.day = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
    }
    // console.log("=================")
    // console.log(distance)
    // console.log("=================")

  }

  cerrarModal() {
    this.n_vez = 0;

    this.clock.unsubscribe()
    this.__VistaCuentaRegresivaService.ocultarModal()
  }

}
