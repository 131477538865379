import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VistaCuentaRegresivaService {

  public oculto="oculto";

  public fechaFin=null;

  constructor() { }

  @Output() cerrarCuentaRegresiva: EventEmitter<boolean> = new EventEmitter();
  __cerrarCuentaRegresiva()
  {
    this.cerrarCuentaRegresiva.emit();
  }


  ocultarModal()
   {
    this.oculto="oculto";

    this.fechaFin=null;
   }
   mostrarModal(fechaFin=null)
   {
    this.oculto="";
    
    this.fechaFin=fechaFin;
   }

}
