<div class="fondo-negro animated fadeIn" [ngClass]="_ModalComentariosService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" style="overflow: auto;max-height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Comentarios</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body" align="center">

                    <div class="row">

                        <div class="row p-2" [ngClass]="_ModalComentariosService.oculto" style="width: 101%;">
                            <div id="cajaChat1" class="w-100" style="overflow: overlay;max-height: 280px;">
                                <ng-container *ngFor="let m of comments">
                                    <!-- <ng-container  *ngIf="m.idUsuarioEmisor!=usuarioActual.id"> -->
                                    <div *ngIf="m.idUsuarioEmisor!==usuarioActual.id && usuarioActual.id!==0"
                                        class="col-12 p-0" style="clear: both;">
                                        <div class="row" style="margin: 0px 12px;width: 94%;">
                                            <div class="col-2 p-0">
                                                <img src="assets/img/portrait/avatars/avatar-08.png"
                                                    class="rounded-circle img-border gradient-summer" height="35"
                                                    width="35" alt="Card image">
                                                <span class="text align-center font-small-2">{{
                                                    m.email.replace('@apolomultimedia.com', '') }}</span>
                                            </div>
                                            <div class="col-8 mt-1 text-white"
                                                style="background: #5A5A5A;border-radius: 15px;padding: 15px;">
                                                <p>{{m.mensaje}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </ng-container> -->

                                    <!-- <ng-container *ngIf="m.idUsuarioEmisor==usuarioActual.id"> -->
                                    <div *ngIf="m.idUsuarioEmisor===usuarioActual.id && usuarioActual.id!==0"
                                        class="col-12 p-0" style="clear: both;">
                                        <div class="mt-1 float-right p-2 text-white"
                                            style="background: #0084ff;border-radius: 15px; width: 250px;">
                                            <p>
                                                {{m.mensaje}}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- </ng-container> -->
                                </ng-container>


                            </div>


                            <div class="col-12 p-0">
                                <div class="row" style="margin: 0px 12px;">
                                    <div class="col-11 mt-1 bg-white">
                                        <textarea [(ngModel)]="myCommentForSend" (keyup.enter)="sendComment()"
                                            class="w-100" name="myCommentForSend" id="" cols="42" rows="3"></textarea>
                                    </div>
                                    <div class="col-1 p-0">
                                        <i (click)="sendComment()" class="fa fa-paper-plane fa-2x" aria-hidden="true"
                                            style="margin-top: 40%;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>





                </div>
            </div>
        </div>
    </div>
</div>