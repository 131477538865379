import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VistaSprintBackLogItemService {

  public oculto="oculto";

  public data=null;

  constructor() { }


  ocultarModal()
   {
    this.oculto="oculto";

    this.data=null;
   }
   mostrarModal(data=null)
   {
    this.oculto="";
    
    this.data=data.data;
   }
}
