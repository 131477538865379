import { Injectable } from '@angular/core';

import {productBacklog} from "../../models/productBacklog.model"

@Injectable({
  providedIn: 'root'
})
export class ModalProductBacklogService {

  public oculto="oculto";

  public productBacklog:productBacklog=null;
  public idProyecto=0;

  public type="";

  public modoVista=false;

  constructor() { }


  ocultarModal()
   {
    this.oculto="oculto";

    this.idProyecto=0;
    this.productBacklog=null;
    this.type="";
   }
   mostrarModal(type="",idProyecto=0,productBacklog:productBacklog=null)
   {
    this.oculto="";
    
    this.idProyecto=idProyecto;
    this.productBacklog=productBacklog;
    this.type=type;

    if(this.type=="ver")
    {
      this.modoVista=true;
    }else{
      this.modoVista=false;
    }
   }
}
