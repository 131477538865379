import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import swal from 'sweetalert2';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesProyectoService {

  constructor(
    public http: HttpClient
  ) {}

  @Output() refreshItem: EventEmitter<boolean> = new EventEmitter();
  __refreshItem()
  {
    this.refreshItem.emit();
  }


  buscarPorProyectoYUsuario(data)
  {
    // data.idProyecto="";

    let url=URL_SERVICIOS+ "rolesProyecto/getRolesProyectos?idProyecto="+data.idProyecto+"&token="+data.token;
    
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  existeProductOwner(data)
  {
    let url=URL_SERVICIOS+ "rolesProyecto/existeProductOwner?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  existeScrumMaster(data)
  {
    let url=URL_SERVICIOS+ "rolesProyecto/existeScrumMaster?idProyecto="+data.idProyecto+"&token="+data.token;
    
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }
  
  buscarPorProyecto(data)
  {
    let url=URL_SERVICIOS+ "rolesProyecto/getAllUsersInProject?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  agregarRolProyecto(data)
  {
    let url= URL_SERVICIOS+ "rolesProyecto/agregarRolProyecto";

    
    return this.http.post(url, data)
        .map((resp: any) =>
          {
            return resp;
          })
          .catch(err=>{
            console.log("+++++++++++++++++++++++++");
            console.log(err);
            console.log("+++++++++++++++++++++++++");
            return of(err);
          });
  }

  eliminarRolProyecto(data)
  {
    let url= URL_SERVICIOS+ "rolesProyecto/eliminarRolProyecto";

    
    return this.http.post(url, data)
        .map((resp: any) =>
          {
            return resp;
          })
          .catch(err=>{
            console.log("+++++++++++++++++++++++++");
            console.log(err);
            console.log("+++++++++++++++++++++++++");
            return of(err);
          });
  }

}
