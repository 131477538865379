<div class="fondo-negro animated fadeIn" [ngClass]="__ModalSprintBacklogService.oculto">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" style="overflow: auto;height: 660px;">

        <div class="modal-header">
          <h2 class="modal-title">SprintBacklog Item</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

        </div>
        <div class="modal-body" align="center">

          <div class="row">
            <div class="col-12">
              <div class="accordion" id="accordionExample">

                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                        data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <h4 class="text-bold-600">Escoger Product BackLog</h4>
                      </button>
                    </h2>
                  </div>

                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div class="card-body">

                      <div class="row">
                        <div *ngFor="let pb of productBacklog" class="col-3">
                          <button id="btnEscogerProductBacklog_{{pb.PB_id}}" (click)="escogerProductBacklog(pb)" style="
                                        border: 0px;
                                        background-color: #fff;
                                    ">
                            <img src="assets/img/icons/productBacklog.png" alt="" class="d-block m-auto w-50">
                            <h5 class="text-center mt-2">{{ pb.PB_titulo }}</h5>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h2 class="mb-0">
                      <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <h4 class="text-bold-600">Escoger Responsable</h4>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">

                      <div class="row">

                        <ng-container *ngFor="let uR of usuarioRolesData">


                          <div *ngIf="uR.rolEnProyecto==='TEAM_DEV'" class="col-xl-4 col-lg-6 col-12">
                            <button id="btnEscogerResponsable_{{uR.idRolProyecto}}" (click)="escogerResponsable(uR)"
                              style="width: 100%;border: 0px;background-color: #fff;">
                              <div class="card">
                                <div class="card-content">

                                  <div class="card-body">
                                    <i class="ft-user success font-large-2"></i>
                                    <p class="card-text">{{ uR.email}} </p>
                                    <p class="card-text">{{ uR.rolEnProyecto}} </p>
                                  </div>

                                </div>
                              </div>
                            </button>

                          </div>
                        </ng-container>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div class="row mb-3">

            <div class="col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-bold-500" for="horizontal-form-4">Product Backlog</label>
                <div class="col-md-9">

                  <div class="input-group">
                    <input readonly
                      [className]="sprintBacklogERROR.PB !== '' ? 'form-control border-danger' : 'form-control'"
                      type="text" class="form-control square" [(ngModel)]="jsonProductBacklog.titulo" name="titulo">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-primary" (click)="toggleContenidoProductBacklog()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <p class="text-danger">{{sprintBacklogERROR.PB}}</p>
                </div>
              </div>
            </div>

            <div id="cajaContenidoProductBacklog" style="display: none;">
              <div class="row w-100">

                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-4">Título</label>
                    <div class="col-md-9">
                      <input readonly type="text" class="form-control square" [(ngModel)]="jsonProductBacklog.titulo"
                        name="titulo">
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-4">Horas</label>
                    <div class="col-md-9">
                      <input readonly type="number" min="1" min="100" class="form-control square"
                        [(ngModel)]="jsonProductBacklog.horas" name="horas">
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-4">Dias</label>
                    <div class="col-md-9">
                      <input readonly type="number" min="1" min="100" class="form-control square"
                        [(ngModel)]="jsonProductBacklog.dias" name="dias">
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-4">Prioridad</label>
                    <div class="col-md-9">
                      <input readonly type="text" class="form-control square" [(ngModel)]="jsonProductBacklog.prioridad"
                        name="prioridad">
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-4">Orden</label>
                    <div class="col-md-9">
                      <input readonly type="number" min="1" min="100" class="form-control square"
                        [(ngModel)]="jsonProductBacklog.orden" name="orden">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-4">Descripción</label>
                    <div class="col-md-9">
                      <textarea rows="4" class="form-control" readonly [(ngModel)]="jsonProductBacklog.detalle"
                        name="detalle"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-bold-500" for="horizontal-form-4">Responsable</label>
                <div class="col-md-9">
                  <input readonly
                    [className]="sprintBacklogERROR.Responsable !== '' ? 'form-control border-danger' : 'form-control'"
                    type="text" class="form-control square" [(ngModel)]="jsonResponsable.Nombre" name="Responsable">
                  <p class="text-danger">{{sprintBacklogERROR.Responsable}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-bold-500" for="horizontal-form-4">Título</label>
                <div class="col-md-9">
                  <input autocomplete="none"
                    [className]="sprintBacklogERROR.titulo !== '' ? 'form-control border-danger' : 'form-control'"
                    type="text" class="form-control square" [(ngModel)]="sprintBacklogItem.titulo" name="titulo">
                  <p class="text-danger">{{sprintBacklogERROR.titulo}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-bold-500" for="horizontal-form-4">Horas</label>
                <div class="col-md-9">
                  <input [className]="sprintBacklogERROR.horas !== '' ? 'form-control border-danger' : 'form-control'"
                    type="number" min="1" max="100" (keypress)="keyPressForNumber($event)" class="form-control square"
                    [(ngModel)]="sprintBacklogItem.horas" name="horas">
                  <p class="text-danger">{{sprintBacklogERROR.horas}}</p>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-6 col-12">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="horizontal-form-4">Dias</label>
                        <div class="col-md-9">
                        <input type="number" min="1" min="100" class="form-control square" [(ngModel)]="sprintBacklogItem.dias" name="dias">
                        </div>
                    </div>
                  </div> -->

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-bold-500" for="horizontal-form-4">Prioridad</label>
                <div class="col-md-9">
                  <select class="form-control" [(ngModel)]="sprintBacklogItem.prioridad" name="prioridad">
                    <option value="ALTA">ALTA</option>
                    <option value="MEDIA">MEDIA</option>
                    <option value="BAJA">BAJA</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 d-none">
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-bold-500" for="horizontal-form-4">Orden</label>
                <div class="col-md-9">
                  <input [className]="sprintBacklogERROR.orden !== '' ? 'form-control border-danger' : 'form-control'"
                    type="number" min="1" max="100" (keypress)="keyPressForNumber($event)" class="form-control square"
                    [(ngModel)]="sprintBacklogItem.orden" name="orden">
                  <p class="text-danger">{{sprintBacklogERROR.orden}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label  text-bold-500" for="horizontal-form-4">Estado</label>
                <div class="col-md-9">
                  <select class="form-control" [(ngModel)]="sprintBacklogItem.estado" name="estado">
                    <option value="1">ACTIVO</option>
                    <option value="0">INACTIVO</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label  text-bold-500" for="horizontal-form-4">Fecha Inicio</label>
                <div class="col-md-9">
                  <input
                    [className]="sprintBacklogERROR.fecha_inicio !== '' ? 'form-control border-danger' : 'form-control'"
                    type="datetime-local" max="{{sprintBacklogItem.fecha_fin}}" class="form-control square"
                    [(ngModel)]="sprintBacklogItem.fecha_inicio" name="fecha_inicio">
                  <p class="text-danger">{{sprintBacklogERROR.fecha_inicio}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label  text-bold-500" for="horizontal-form-4">Fecha Fin</label>
                <div class="col-md-9">
                  <input
                    [className]="sprintBacklogERROR.fecha_fin !== '' ? 'form-control border-danger' : 'form-control'"
                    type="datetime-local" min="{{sprintBacklogItem.fecha_inicio}}" class="form-control square"
                    [(ngModel)]="sprintBacklogItem.fecha_fin" name="fecha_fin">
                  <p class="text-danger">{{sprintBacklogERROR.fecha_fin}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">

            </div>

            <div class="col-12">
              <div class="form-group row">
                <label class="col-md-2 col-form-label  text-bold-500" for="horizontal-form-4">Detalle</label>
                <div class="col-md-10">
                  <textarea rows="4" class="form-control" [(ngModel)]="sprintBacklogItem.detalle"
                    name="detalle"></textarea>
                </div>
              </div>
            </div>

          </div>


        </div>
        <div class="modal-footer">
          <button (click)="cerrarModal()" type="button" class="btn btn-danger">Cerrar</button>

          <button *ngIf="__ModalSprintBacklogService.type==='crear'" (click)="agregarSBI()" type="button"
            class="btn btn-primary float-right">Agregar</button>
          <button *ngIf="__ModalSprintBacklogService.type==='update'" (click)="updateSBI()" type="button"
            id="btn_updatePB" class="btn btn-primary">Actualizar</button>
        </div>
      </div>
    </div>
  </div>
</div>