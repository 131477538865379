import { Component } from '@angular/core';
import { CargandoService } from './cargando.service';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.css']
})
export class CargandoComponent {

  constructor(
    public _Cargando: CargandoService
  ) { }

}
