import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductBacklogService {

  constructor(
    public http: HttpClient
  ) {}

  @Output() refreshProductBacklog: EventEmitter<boolean> = new EventEmitter();
  __refreshProductBacklog()
  {
    this.refreshProductBacklog.emit();
  }

  agregarPB(data)
  {
    
    let url=URL_SERVICIOS+ "productBacklog/agregarPB";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }

  updatePB(data)
  {
    
    let url=URL_SERVICIOS+ "productBacklog/updatePB";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                    });
  }

  getSprintsBacklogOfPB(data)
  {
    let url=URL_SERVICIOS+ "productBacklog/getSprintsBacklogOfPB?productBacklogId="+data.productBacklogId+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  getProductBacklog(data)
  {
    let url=URL_SERVICIOS+ "productBacklog/getProductBacklog?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  eliminarPB(data_enviar){
    let url = URL_SERVICIOS+"productBacklog/eliminarPB";
    
    return this.http.post(url, data_enviar)
                 .map((resp: any)=>
                   {
                     return resp;
                   })
                   .catch(err=>{
                     console.log("----------------");
                     console.log(err);
                     console.log("----------------");
                     
                     return of(err);
                   });
  }
}
