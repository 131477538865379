import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VistaHistoriaUsuarioBaseComponent } from './vista-historia-usuario-base/vista-historia-usuario-base.component';
import { VistaProyectoComponent } from './vista-proyecto/vista-proyecto.component';



@NgModule({
  declarations: [
    VistaHistoriaUsuarioBaseComponent,
    VistaProyectoComponent,
  ],
  imports: [
    CommonModule
  ],
  exports:[
    VistaHistoriaUsuarioBaseComponent,
    VistaProyectoComponent,
  ]
})
export class ComponentesModule { }
