<div class="wrapper">
  <div class="main-panel">
  <!-- BEGIN : Main Content-->
  <div class="main-content" style="padding:0px;">
    <div class="content-wrapper" style="padding:0px;"><!--Error page starts-->
      <section id="error">
        <div class="container-fluid forgot-password-bg overflow-hidden">
          <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <img src="assets/img/gallery/error.png" alt="" class="img-fluid error-img mt-2" height="300"
                  width="500">
                  <h1 class="text-white mt-4">404 - Page Not Found!</h1>
                  <div class="w-75 error-text mx-auto mt-4">
                    <p class="text-white">paraphonic unassessable foramination Caulopteris worral Spirophyton
                    encrimson esparcet aggerate chondrule restate whistler shallopy biosystematy area
                    bertram plotting unstarting quarterstaff.</p>
                  </div>
                  <button class="btn btn-primary btn-lg mt-3"><a class="text-decoration-none text-white" [routerLink]="['/dashboard']">Back To Home</a></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--Error page ends-->

    </div>
  </div>
  <!-- END : End Main Content-->
  </div>
</div>