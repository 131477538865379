import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModalParticipanteService {

  idProyecto=0

  public oculto="oculto";
  
  constructor(
    public http: HttpClient
  ) { }

  ocultarModal()
   {
    this.oculto="oculto";
    this.idProyecto=0;
   }
   
   mostrarModal(idProyecto)
   {
    this.oculto="";
    this.idProyecto=idProyecto;
    
   }
}
