import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsuarioService } from '../services/service.index';

import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email

  constructor(
    public router: Router,
    public _usuarioService: UsuarioService,
    public toastr: ToastrService,
  ) {

  }

  ngOnInit() {

    init_plugins();
  }


  ingresar(forma: NgForm) {

    let data = {
      "_id": "",
      "email": forma.value.email,
      "password": forma.value.password
    }

    this._usuarioService.buscarUsuario(data).
      subscribe((response) => {
        if (!response.ok) {
          this.toastr.error('Usuario no encontrado', 'error!', { timeOut: 5000 });
          return;
        }


        data._id = response.data[0].id;

        if (!forma.value.recuerdame) {
          forma.value.recuerdame = false;
        }


        this._usuarioService.login(data, forma.value.recuerdame)
          .subscribe((response) => {
            if (!response.ok) {
              this.toastr.error(response.mensaje, 'Ocurrió un error!', { timeOut: 5000 });
              return;
            }

            this.router.navigate(["/dashboard"])
          });
      });


    // this.router.navigate(['/dashboard']);
  }


}
