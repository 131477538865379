import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Rutas
import { APP_ROUTES } from './app-routing.module';

// Modulos
import { PagesModule } from './pages/pages.module';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ChartsModule } from 'ng2-charts';


// Servicios

import { ServiceModule } from './services/service.module';



// Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';
import { PagesComponent } from './pages/pages.component';
import { sharedModule } from './shared/shared.module';
import { RecuperarClaveComponent } from './login/recuperar-clave/recuperar-clave.component';
import { CargandoComponent } from './componentes/animacion/cargando/cargando.component';
import { ModalGestionRolesComponent } from './componentes/modal-gestion-roles/modal-gestion-roles.component';
import { ModalCrearProyectoComponent } from './componentes/modal-crear-proyecto/modal-crear-proyecto.component';

import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ModalUsuarioComponent } from './componentes/modal-usuario/modal-usuario.component';
import { ModalProductBacklogComponent } from './componentes/modal-product-backlog/modal-product-backlog.component';
import { ModalHistoriaUsuarioComponent } from './componentes/modal-historia-usuario/modal-historia-usuario.component';
import { SprintBacklogItemComponent } from './componentes/modal-sprint-backlog-item/sprint-backlog-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VistaSprintBackLogItemComponent } from './componentes/vista-sprint-back-log-item/vista-sprint-back-log-item.component';
import { VistaCuentaRegresivaComponent } from './componentes/vista-cuenta-regresiva/vista-cuenta-regresiva.component';
import { ModalParticipanteComponent } from './componentes/modal-participante/modal-participante.component';

// environment
// import { environment } from 'src/environments/environment';

// sockets
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ModalEntregableComponent } from './componentes/modal-entregable/modal-entregable.component';
import { VistaImagenComponent } from './componentes/vista-imagen/vista-imagen.component';
import { ModalComentariosComponent } from './componentes/modal-comentarios/modal-comentarios.component';
import { ComponentesModule } from './componentes/componentes.module';
import { ToastrModule } from 'ngx-toastr';
const url = 'https://spmbackendsocket.standfieldsdev.com';
const config: SocketIoConfig = {
  url, options: {}
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    PagesComponent,
    RecuperarClaveComponent,
    CargandoComponent,
    ModalGestionRolesComponent,
    ModalCrearProyectoComponent,
    ModalUsuarioComponent,
    ModalProductBacklogComponent,
    ModalHistoriaUsuarioComponent,
    SprintBacklogItemComponent,
    VistaSprintBackLogItemComponent,
    VistaCuentaRegresivaComponent,
    ModalParticipanteComponent,
    ModalEntregableComponent,
    VistaImagenComponent,
    ModalComentariosComponent,
  ],
  exports: [

  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule,
    sharedModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    SocketIoModule.forRoot(config),
    ChartsModule,
    ComponentesModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
