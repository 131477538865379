import { Injectable } from '@angular/core';
import { sprintBacklogItem } from 'src/app/models/sprintBacklogItem.model';

@Injectable({
  providedIn: 'root'
})
export class ModalSprintBacklogService {

  public oculto="oculto";

  public sprintBacklogItem:sprintBacklogItem=null;
  public idProyecto=0;
  public idSprintBacklog=0;

  public type="";

  constructor() { }


  ocultarModal()
   {
    this.oculto="oculto";

    this.idProyecto=0;
    this.idSprintBacklog=0;
    this.sprintBacklogItem=null;
    this.type="";
   }
   mostrarModal(type="",idProyecto=0,idSprintBacklog=0,sprintBacklogItem:sprintBacklogItem=null)
   {
    this.oculto="";
    // console.log(sprintBacklogItem)
    this.idProyecto=idProyecto;
    this.idSprintBacklog=idSprintBacklog;
    this.sprintBacklogItem=sprintBacklogItem;
    this.type=type;
   }
}
