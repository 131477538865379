import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import swal from 'sweetalert2';

import { of } from 'rxjs';
import { UsuarioService } from 'src/app/services/service.index';
import { WebSocketServerService } from 'src/app/services/web-socket-server.service';

@Injectable({
  providedIn: 'root'
})
export class ModalGestionRolesService {

  public oculto="oculto";

  idProyecto:any;

  constructor(
    public http: HttpClient,
    public __usuarioService:UsuarioService,
  ) { }

  ocultarModal()
   {
    this.oculto="oculto";
    this.idProyecto="";
   }
   
   mostrarModal(idProyecto)
   {
     this.idProyecto=idProyecto;
    this.oculto="";
   }


}
