import { Component, DoCheck } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import { ModalEntregableService } from './modal-entregable.service';
import { URL_SERVICIOS } from 'src/app/config/config';

import swal from 'sweetalert2';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-modal-entregable',
  templateUrl: './modal-entregable.component.html'
})
export class ModalEntregableComponent implements DoCheck {

  ENTREGABLE: any = {
    "idEntregable": "",
    "detalle": "",
    "titulo": "",
    "tipoEntregable": "PFD",
    "token": "",
  }

  dataAdicional: any = {
    "idSprintBacklog": "",
    "idProyecto": "",
  }

  ENTREGABLEERROR: any = {
    "detalle": "",
    "titulo": "",
  }

  IMAGENES = []

  PATH_ROOT = URL_SERVICIOS + "upload/"


  files: File[] = [];

  n_vez = 0

  constructor(
    public _ModalEntregableService: ModalEntregableService,
    public _usuarioService: UsuarioService,
    public _NotificacionesService: NotificacionesService,
  ) { }

  ngDoCheck() {

    if (this._ModalEntregableService.oculto == "" && this.n_vez == 0 && this._ModalEntregableService.tipo == "update") {
      this.ENTREGABLE.idEntregable = this._ModalEntregableService.idEntregable;
      this.ENTREGABLE.titulo = this._ModalEntregableService.data.titulo;
      this.ENTREGABLE.detalle = this._ModalEntregableService.data.detalle;
      if (this._ModalEntregableService.data.tipo) {
        this.ENTREGABLE.tipoEntregable = this._ModalEntregableService.data.tipo;
      }

      this.getEntregableById(this._ModalEntregableService.idEntregable)
      setTimeout(() => this.getImagenes(this._ModalEntregableService.idEntregable), 500);

      this.n_vez = 1;

    }
  }

  eliminarImagen(imagen_ESBI_ID) {
    let data = {
      "idImagen_ESBI": imagen_ESBI_ID.id,
      "nombreBackend": imagen_ESBI_ID.nombreBackend,
      "extension": imagen_ESBI_ID.extension,
      "token": this._usuarioService.token
    }

    this._ModalEntregableService.eliminarImagenESBI(data)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.getImagenes(this._ModalEntregableService.idEntregable)
        }
      });

  }


  limpiar() {
    this.ENTREGABLEERROR = {
      "titulo": "",
      "detalle": "",
    }
  }

  getImagenes(idEntregable) {
    let data = {
      "idEntregable": idEntregable,
      "token": this._usuarioService.token
    }

    this._ModalEntregableService.getImagenes(data)
      .subscribe((resp2: any) => {
        this.IMAGENES = resp2.data;
      });
  }

  getEntregableById(idEntregable) {
    let data = {
      "idEntregable": idEntregable,
      "token": this._usuarioService.token
    }

    this._ModalEntregableService.getEntregableById(data)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.dataAdicional.idSprintBacklog = resp2.data[0].SB_id;
          this.dataAdicional.idProyecto = resp2.data[0].SBI_proyecto;
        }
      });
  }

  onSelect(event) {

    let elemento = event.target;

    if (elemento.value.split('.')[1] != "png" && elemento.value.split('.')[1] != "jpg" && elemento.value.split('.')[1] != "jpeg" && elemento.value.split('.')[1] != "JPG") {
      swal.fire("Remember that you can only upload images of type png, jpg and jpeg", "", "error");

      (<HTMLInputElement>document.getElementById("file-upload")).value = "";

      return;
    }

    if (elemento.files.length > 0) {


      let formData = new FormData();
      formData.append('token', this._usuarioService.token);
      formData.append('idEntregableSBI', this.ENTREGABLE.idEntregable);
      formData.append('idSprintBacklog', this.dataAdicional.idSprintBacklog);
      formData.append('idProyecto', this.dataAdicional.idProyecto);

      formData.append('file', elemento.files[0]);

      this._ModalEntregableService.subirImagen(formData)
        .subscribe((data: any) => {
          if (data.ok) {
            this.getImagenes(this.ENTREGABLE.idEntregable)
            // this.cargarItems(this.productOrder_Id);
            // this.actualizarUno();
          }
          (<HTMLInputElement>document.getElementById("file-upload")).value = "";
          return;

        });

    }


  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }



  cerrarModal() {
    this.n_vez = 0;

    this.ENTREGABLE = {
      "idEntregable": "",
      "detalle": "",
      "titulo": "",
      "tipoEntregable": "PFD",
      "token": "",
    }

    this.ENTREGABLEERROR = {
      "detalle": "",
      "titulo": "",
    }


    this.dataAdicional = {
      "idSprintBacklog": "",
      "idProyecto": "",
    }

    this.IMAGENES = []

    this.files = [];


    this._ModalEntregableService.ocultarModal();
  }



  actualizarEntregable() {
    if (this.ENTREGABLE.titulo == "" || !this.ENTREGABLE.titulo) { this.ENTREGABLEERROR.titulo = "Este campo no puede estar vacio"; return; } this.limpiar();
    if (this.ENTREGABLE.detalle == "" || !this.ENTREGABLE.detalle) { this.ENTREGABLEERROR.detalle = "Este campo no puede estar vacio"; return; } this.limpiar();

    this.ENTREGABLE.token = this._usuarioService.token;
    this.ENTREGABLE.idProyecto = this.dataAdicional.idProyecto;
    this.ENTREGABLE.idSBI = this._ModalEntregableService.idSBI;

    this._ModalEntregableService.actualizarEntregable(this.ENTREGABLE)
      .subscribe((resp2: any) => {
        this._NotificacionesService.emitirNotificaciones(0);
        this._ModalEntregableService.__refreshEntregable();
        this.cerrarModal();
      });
  }


}
