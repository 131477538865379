import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';

import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalEntregableService {

  public oculto="oculto";

  idEntregable:any;
  idSBI:any;
  tipo=""
  data={
    "titulo":"",
    "tipo":"",
    "detalle":"",
  }

  constructor(
    public http: HttpClient,
  ) { }

  @Output() refreshEntregable: EventEmitter<boolean> = new EventEmitter();
  __refreshEntregable()
  {
    this.refreshEntregable.emit();
  }

  ocultarModal()
   {
    this.oculto="oculto";
    this.idEntregable="";
    this.idSBI=0;
    this.tipo="";
   }
   
   mostrarModal(idEntregable,tipo,data,idSBI)
   {
     this.idEntregable=idEntregable;
     this.tipo=tipo;
     this.data=data;
     this.idSBI=idSBI;


    this.oculto="";
   }

   agregarEntregable(data)
   {
    let url=URL_SERVICIOS+ "entregable/agregarEntregable";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                    });
  }

  actualizarEntregable(data)
   {
    let url=URL_SERVICIOS+ "entregable/actualizarEntregable";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                    });
  }

  subirImagen(data)
   {
    let url=URL_SERVICIOS+ "entregable/subirImagen";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                    });
  }

  eliminarImagenESBI(data)
   {
    let url=URL_SERVICIOS+ "entregable/eliminarImagenESBI";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                    });
  }

  eliminarESBI(data)
   {
    let url=URL_SERVICIOS+ "entregable/eliminarESBI";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                    });
  }

  marcarFinalizado(data)
   {
    let url=URL_SERVICIOS+ "entregable/marcarFinalizado";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                    });
  }

  getDataVistaGraficaEntregableTipo(data)
  {
    let url=URL_SERVICIOS+ "entregable/getDataVistaGraficaEntregableTipo?idSprintBacklog="+data.idSprintBacklog+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  getEntregableByIdProject(data)
  {
    let url=URL_SERVICIOS+ "entregable/getEntregableByIdProject?idProyecto="+data.idProyecto+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  getImagenes(data)
  {
    let url=URL_SERVICIOS+ "entregable/getImagenes?idEntregable="+data.idEntregable+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  getEntregableByIdSprintItem(data)
  {
    let url=URL_SERVICIOS+ "entregable/getEntregableByIdSprintItem?idSprintBacklogItem="+data.idSprintBacklogItem+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }

  getEntregableById(data)
  {
    let url=URL_SERVICIOS+ "entregable/getEntregableById?idEntregable="+data.idEntregable+"&token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        return of(err);
      });
  }
}
