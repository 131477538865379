<div class="fondo-negro animated fadeIn" [ngClass]="__modalProductBacklogService.oculto">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" style="overflow: auto;height: 660px;">

        <div class="modal-header">
          <h2 class="modal-title font-weight-bolder">Product Backlog</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

        </div>
        <div class="modal-body" align="center">

          <div [hidden]="__modalProductBacklogService.modoVista" class="card card-outline-secondary text-center">
            <app-vista-historia-usuario-base [modo]="'BY_PROJECT'" [historiasDeUsuarios]="historiaUsuario"
              [idProyecto]="jsonProductBacklog.idProyecto" [idChooseHU]="idChooseHU"
              (emitEstablecer)="escogerHistoriaUsuario($event)"></app-vista-historia-usuario-base>
          </div>

          <div [hidden]="__modalProductBacklogService.modoVista" class="row mb-3">

            <div class="col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-bold-500" for="horizontal-form-4">Historia de usuario</label>
                <div class="col-md-9">

                  <div class="input-group">
                    <input [disabled]="__modalProductBacklogService.modoVista" readonly
                      [className]="productBacklogERROR.HU !== '' ? 'form-control border-danger' : 'form-control'"
                      type="text" class="form-control square" [(ngModel)]="jsonHU.titulo" name="titulo">
                    <div class="input-group-append">
                      <button *ngIf="estadoMostrarFormHU===false" type="button" class="btn btn-primary"
                        (click)="estadoMostrarFormHU=true">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </button>

                      <button *ngIf="estadoMostrarFormHU===true" type="button" class="btn btn-primary"
                        (click)="estadoMostrarFormHU=false">
                        <i class="fa fa-close" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <p class="text-danger">{{productBacklogERROR.HU}}</p>
                </div>
              </div>
            </div>

            <app-vista-historia-usuario-base [modo]="'DISPLAY_FORM_HU'" [jsonHU]="jsonHU"
              [estado]="estadoMostrarFormHU"></app-vista-historia-usuario-base>
          </div>

          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label font-weight-bold" for="horizontal-form-4">Título</label>
                <div class="col-md-9">
                  <input *ngIf="__modalProductBacklogService.modoVista===false" autocomplete="none" maxlength="195"
                    [className]="productBacklogERROR.titulo !== '' ? 'form-control border-danger' : 'form-control'"
                    type="text" class="form-control square" [(ngModel)]="jsonProductBacklog.titulo" name="titulo">
                  <p *ngIf="__modalProductBacklogService.modoVista" class="mt-1">{{jsonProductBacklog.titulo}}</p>
                  <p class="text-danger">{{productBacklogERROR.titulo}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label font-weight-bold" for="horizontal-form-4">Horas</label>
                <div class="col-md-9">
                  <input *ngIf="__modalProductBacklogService.modoVista===false" (change)="validarCampo('horas')"
                    type="number" min="1" max="100" class="form-control square" [(ngModel)]="jsonProductBacklog.horas"
                    name="horas">
                  <p *ngIf="__modalProductBacklogService.modoVista" class="mt-1">{{jsonProductBacklog.horas}}</p>
                  <p class="text-danger">{{productBacklogERROR.horas}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label font-weight-bold" for="horizontal-form-4">Dias</label>
                <div class="col-md-9">
                  <input *ngIf="__modalProductBacklogService.modoVista===false" (change)="validarCampo('dias')"
                    type="number" min="1" max="100" class="form-control square" [(ngModel)]="jsonProductBacklog.dias"
                    name="dias">
                  <p *ngIf="__modalProductBacklogService.modoVista" class="mt-1">{{jsonProductBacklog.dias}}</p>
                  <p class="text-danger">{{productBacklogERROR.dias}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label font-weight-bold" for="horizontal-form-4">Prioridad</label>
                <div class="col-md-9">
                  <select *ngIf="__modalProductBacklogService.modoVista===false" class="form-control"
                    [(ngModel)]="jsonProductBacklog.prioridad" name="prioridad">
                    <option value="ALTA">ALTA</option>
                    <option value="MEDIA">MEDIA</option>
                    <option value="BAJA">BAJA</option>
                  </select>
                  <p *ngIf="__modalProductBacklogService.modoVista" class="mt-1">{{jsonProductBacklog.prioridad}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label font-weight-bold" for="horizontal-form-4">Orden</label>
                <div class="col-md-9">
                  <input *ngIf="__modalProductBacklogService.modoVista===false" (change)="validarCampo('orden')"
                    type="number" min="1" max="100" class="form-control square" [(ngModel)]="jsonProductBacklog.orden"
                    name="orden">
                  <p class="text-danger">{{productBacklogERROR.orden}}</p>
                  <p *ngIf="__modalProductBacklogService.modoVista" class="mt-1">{{jsonProductBacklog.orden}}</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label font-weight-bold" for="horizontal-form-4">Estado</label>
                <div class="col-md-9">
                  <select *ngIf="__modalProductBacklogService.modoVista===false" class="form-control"
                    [(ngModel)]="jsonProductBacklog.estado" name="estado">
                    <option value="1">ACTIVO</option>
                    <option value="0">INACTIVO</option>
                  </select>
                  <p *ngIf="__modalProductBacklogService.modoVista" class="mt-1">{{jsonProductBacklog.estado}}</p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group row">
                <label class="col-md-2 col-form-label font-weight-bold" for="horizontal-form-4">Detalle</label>
                <div class="col-md-10">
                  <textarea [disabled]="__modalProductBacklogService.modoVista" maxlength="1495"
                    [className]="productBacklogERROR.detalle !== '' ? 'form-control border-danger' : 'form-control'"
                    rows="4" class="form-control" [(ngModel)]="jsonProductBacklog.detalle" name="detalle"></textarea>
                  <p class="text-danger">{{productBacklogERROR.detalle}}</p>
                  <!-- <p *ngIf="__modalProductBacklogService.modoVista" class="mt-1">{{jsonProductBacklog.estado}}</p> -->
                </div>
              </div>
            </div>

          </div>


        </div>
        <div *ngIf="__modalProductBacklogService.modoVista===false" class="modal-footer">
          <button (click)="cerrarModal()" type="button" class="btn btn-danger">Cerrar</button>

          <button *ngIf="__modalProductBacklogService.type==='crear'" (click)="agregarPB()" type="button"
            class="btn btn-primary float-right">Agregar</button>
          <button *ngIf="__modalProductBacklogService.type==='update'" (click)="updatePB()" type="button"
            id="btn_updatePB" class="btn btn-primary">Actualizar</button>
        </div>
      </div>
    </div>
  </div>
</div>