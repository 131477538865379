import { Component, DoCheck } from '@angular/core';
import { ModalSprintBacklogService } from './modal-sprint-backlog.service';
import { CargandoService } from '../animacion/cargando/cargando.service';
import { UsuarioService, ProductBacklogService, RolesProyectoService } from 'src/app/services/service.index';
import { sprintBacklogItem } from 'src/app/models/sprintBacklogItem.model';
import { SprintBacklogService } from 'src/app/services/sprint-backlog.service';

@Component({
  selector: 'app-sprint-backlog-item',
  templateUrl: './sprint-backlog-item.component.html'
})
export class SprintBacklogItemComponent implements DoCheck {

  n_vez = 0;
  n_vez2 = 0;

  productBacklog = [];
  usuarioRolesData = [];

  jsonProductBacklog = {
    "titulo": "",
    "horas": "",
    "dias": "",
    "prioridad": "",
    "orden": "",
    "detalle": "",
    "id": ""
  }

  jsonResponsable = {
    "Nombre": "",
    "id": ""
  }

  sprintBacklogItem = {
    "id": "",
    "idUsuarioCreador": "",
    "idProyecto": 0,
    "idSprintBacklog": 0,
    "idProductBacklog": "",
    "idResponsable": "",
    "titulo": "",
    "detalle": "",
    "horas": 1,
    "dias": 0,
    "prioridad": "ALTA",
    "orden": 1,
    "fecha_inicio": "",
    "fecha_fin": "",
    "estado": 1,

    "token": "",
  }

  sprintBacklogERROR = {
    "PB": "",
    "Responsable": "",
    "titulo": "",
    "fecha_inicio": "",
    "fecha_fin": "",
    "horas": "",
    "orden": "",
  }

  constructor(
    public __ModalSprintBacklogService: ModalSprintBacklogService,
    public _Cargando: CargandoService,
    public _usuarioService: UsuarioService,
    public _SprintBacklogService: SprintBacklogService,
    public _ProductBacklogService: ProductBacklogService,
    public _rolesProyecto: RolesProyectoService,
  ) { }

  ngDoCheck(): void {
    if (this.__ModalSprintBacklogService.oculto == "" && this.n_vez == 0 && this.__ModalSprintBacklogService.idProyecto != 0 && this.__ModalSprintBacklogService.idSprintBacklog != 0) {
      this.n_vez = 1;
      this.sprintBacklogItem.idProyecto = this.__ModalSprintBacklogService.idProyecto;
      this.sprintBacklogItem.idSprintBacklog = this.__ModalSprintBacklogService.idSprintBacklog;
      this.getProductBacklog(this.__ModalSprintBacklogService.idProyecto);
      setTimeout(() => this.getRolesEsteProyecto(this.__ModalSprintBacklogService.idProyecto), 250);

    }
  }

  getRolesEsteProyecto(idProyecto) {
    this._usuarioService.obtenerDataToken(this._usuarioService.token)
      .subscribe((dUsu: any) => {
        if (dUsu["usuario"].id) {
          let data = { "idProyecto": idProyecto, "token": this._usuarioService.token }
          this._rolesProyecto.buscarPorProyecto(data)
            .subscribe((resp2: any) => {
              if (resp2.ok) {
                this.usuarioRolesData = resp2.data;
              }
            });
        }

      });
  }

  getProductBacklog(idProyecto) {
    let data = {
      "idProyecto": idProyecto,
      "token": this._usuarioService.token
    }

    this._ProductBacklogService.getProductBacklog(data)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.productBacklog = resp2.data;
        }
      });
  }

  escogerResponsable(data) {
    this.jsonResponsable.id = data.idUsuario;
    this.jsonResponsable.Nombre = data.email;

    this.sprintBacklogItem.idResponsable = data.idUsuario;
    this.limpiarErrores();
  }

  escogerProductBacklog(data) {
    this.jsonProductBacklog.id = data.PB_id;
    this.jsonProductBacklog.titulo = data.PB_titulo;
    this.jsonProductBacklog.horas = data.PB_horas;
    this.jsonProductBacklog.dias = data.PB_dias;
    this.jsonProductBacklog.prioridad = data.PB_prioridad;
    this.jsonProductBacklog.orden = data.PB_orden;
    this.jsonProductBacklog.detalle = data.PB_detalle;

    this.sprintBacklogItem.idProductBacklog = data.PB_id;
    this.limpiarErrores();
  }

  cerrarModal() {

    let element = document.getElementById("cajaContenidoProductBacklog");
    element.style.display = 'none'

    this.n_vez = 0;
    // this.n_vez2=0;

    this.jsonProductBacklog = {
      "titulo": "",
      "horas": "",
      "dias": "",
      "prioridad": "",
      "orden": "",
      "detalle": "",
      "id": ""
    }

    this.jsonResponsable = {
      "Nombre": "",
      "id": ""
    }

    this.sprintBacklogItem = {
      "id": "",
      "idUsuarioCreador": "",
      "idProyecto": 0,
      "idSprintBacklog": 0,
      "idProductBacklog": "",
      "idResponsable": "",
      "titulo": "",
      "detalle": "",
      "horas": 1,
      "dias": 0,
      "prioridad": "ALTA",
      "orden": 1,
      "fecha_inicio": "",
      "fecha_fin": "",
      "estado": 1,

      "token": "",
    }

    this.limpiarErrores();

    this.__ModalSprintBacklogService.ocultarModal();
  }

  toggleContenidoProductBacklog() {
    let element = document.getElementById("cajaContenidoProductBacklog");
    if (element.style.display === 'block') {
      element.style.display = 'none'
    } else {
      element.style.display = 'block'
    }
  }

  agregarSBI() {
    if (this.jsonProductBacklog.id == "") { this.sprintBacklogERROR.PB = "Este campo no puede estar vacio, seleccione una PB"; return; } this.limpiarErrores();
    if (this.jsonResponsable.id == "") { this.sprintBacklogERROR.Responsable = "Este campo no puede estar vacio, seleccione un responsable"; return; } this.limpiarErrores();

    if (this.sprintBacklogItem.titulo == "") { this.sprintBacklogERROR.titulo = "Este campo no puede estar vacio"; return; } this.limpiarErrores();

    if (!this.sprintBacklogItem.horas) { this.sprintBacklogERROR.horas = "Este campo no puede estar vacio"; return; } this.limpiarErrores();
    if (!this.sprintBacklogItem.orden) { this.sprintBacklogERROR.orden = "Este campo no puede estar vacio"; return; } this.limpiarErrores();

    if (this.sprintBacklogItem.fecha_inicio == "") { this.sprintBacklogERROR.fecha_inicio = "Este campo no puede estar vacio"; return; } this.limpiarErrores();
    if (this.sprintBacklogItem.fecha_fin == "") { this.sprintBacklogERROR.fecha_fin = "Este campo no puede estar vacio"; return; } this.limpiarErrores();

    this.sprintBacklogItem.token = this._usuarioService.token;


    this._Cargando.mostrarAnimacion();
    this._SprintBacklogService.agregarSBI(this.sprintBacklogItem)
      .subscribe((resp2: any) => {
        this._Cargando.ocultarAnimacion();

        this._SprintBacklogService.__refreshSprintBacklog2();
        this.cerrarModal();
      });
  }

  limpiarErrores() {
    this.sprintBacklogERROR = {
      "PB": "",
      "Responsable": "",
      "titulo": "",
      "fecha_inicio": "",
      "fecha_fin": "",
      "horas": "",
      "orden": "",
    }
  }

  keyPressForNumber(evt: KeyboardEvent) {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
      evt.preventDefault();
    }
  }

  updateSBI() {

  }

}
