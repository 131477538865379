<nav class="navbar navbar-expand-lg navbar-light bg-faded header-navbar header_custom"
  [ngClass]="{'d-none': headerPegado === 1 }">
  <div class="container-fluid">

    <div class="navbar-header">
      <button type="button" data-toggle="collapse" class="navbar-toggle d-lg-none float-left">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <span class="d-lg-none navbar-right navbar-collapse-toggle">
        <a aria-controls="navbarSupportedContent" href="javascript:;" class="open-navbar-container black">
          <i class="ft-more-vertical"></i>
        </a>
      </span>

      <button type="button" (click)="backClicked()" class="btn btn-primary">
        <i class="ft-arrow-left"></i>
      </button>
    </div>
    <div class="navbar-container">

      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item mr-2 d-none d-lg-block">
            <a id="navbar-fullscreen" href="javascript:;" class="nav-link apptogglefullscreen">
              <i class="ft-maximize font-medium-3 blue-grey darken-4"></i>
              <p class="d-none">fullscreen</p>
            </a>
          </li>



          <li *ngIf="NotificacionQuantity>0" class="dropdown nav-item">
            <a id="dropdownBasic2" href="#" data-toggle="dropdown" class="nav-link position-relative dropdown-toggle">
              <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
              <span class="notification badge badge-pill badge-danger">{{NotificacionQuantity}}</span>
              <p class="d-none">Notifications</p>
            </a>
            <div class="notification-dropdown dropdown-menu dropdown-menu-right">
              <div class="noti-list h-auto">

                <ng-container *ngFor="let not of Notificacion">
                  <a (click)="redigirir(not.id,not.enlaceRedirect)"
                    class="mt-2 mb-2 dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                    <i class="ft-bell info float-left d-block font-large-1 mr-2"></i>
                    <span class="noti-wrapper">
                      <span class="noti-title line-height-1 d-block text-bold-400">{{ not.mensaje }}</span>
                    </span>
                  </a>
                </ng-container>



                <!-- <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                    <i class="ft-bell warning float-left d-block font-large-1 mt-1 mr-2"></i>
                    <span class="noti-wrapper" >
                        <span class="noti-title line-height-1 d-block text-bold-400 warning">New User Registered</span>
                        <span class="noti-text" >Lorem ipsum dolor sit ametitaque in</span>
                    </span>
                </a>
                <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                    <i class="ft-bell danger float-left d-block font-large-1 mt-1 mr-2"></i>
                    <span class="noti-wrapper" >
                        <span class="noti-title line-height-1 d-block text-bold-400 danger">New Order Received</span>
                        <span class="noti-text">Lorem ipsum dolor sit ametest?</span>
                    </span>
                </a>
                    <a class="dropdown-item noti-container py-3">
                        <i class="ft-bell success float-left d-block font-large-1 mt-1 mr-2"></i>
                        <span class="noti-wrapper">
                            <span class="noti-title line-height-1 d-block text-bold-400 success" >New User Registered</span>
                            <span class="noti-text">Lorem ipsum dolor sit ametnatus aut.</span>
                        </span>
                    </a> -->
              </div>

            </div>
          </li>




          <li class="dropdown nav-item">
            <a id="dropdownBasic3" href="#" data-toggle="dropdown" class="nav-link position-relative dropdown-toggle">
              <i class="ft-user font-medium-3 blue-grey darken-4"></i>
              <p class="d-none">User Settings</p>
            </a>
            <div ngbdropdownmenu="" aria-labelledby="dropdownBasic3"
              class="dropdown-menu text-left dropdown-menu-right">


              <a [routerLink]="['/perfil']" class="dropdown-item py-1">
                <i class="ft-edit mr-2"></i>
                <span>Ver perfil</span>
              </a>
              <div class="dropdown-divider"></div>
              <a (click)="_usuarioService.logout()" class="dropdown-item">
                <i class="ft-power mr-2"></i>
                <span>Salir</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>