<div class="fondo-negro animated fadeIn" [ngClass]="__VistaSprintBackLogItemService.oculto">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" style="overflow: auto;height: 660px;">

        <div class="modal-header">
          <h2 class="modal-title">Item sprint</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

        </div>
        <div class="modal-body" align="center">
          <app-vista-cuenta-regresiva></app-vista-cuenta-regresiva>



          <div class="card card-outline-secondary text-center">
            <div class="card-content">
              <div class="card-body pt-3">
                <div class="row">

                  <div class="col-lg-3 col-md-4 col-12">
                    <button (click)="toggleBox('HU')" style="
                              border: 0px;
                              background-color: #fff;
                          ">
                      <p class="mb-0 font-weight-bolder">Historia de usuario</p>
                      <img src="assets/img/icons/historiaUsuario.png" alt="" class="d-block m-auto w-50">
                      <h5 class="text-center mt-2">{{ HU.titulo }}</h5>
                    </button>
                  </div>

                  <div class="col-lg-3 col-md-4 col-12">
                    <button (click)="toggleBox('PB')" style="
                              border: 0px;
                              background-color: #fff;
                          ">
                      <p class="mb-0 font-weight-bolder">Product Backlog</p>
                      <img src="assets/img/icons/productBacklog.png" alt="" class="d-block m-auto w-50">
                      <h5 class="text-center mt-2">{{ PB.titulo }}</h5>
                    </button>
                  </div>

                  <div class="col-lg-3 col-md-4 col-12">
                    <button (click)="toggleBox('SBI')" style="
                              border: 0px;
                              background-color: #fff;
                          ">
                      <p class="mb-0 font-weight-bolder">SprintBacklog Item</p>
                      <img src="assets/img/icons/sprintBacklog.png" alt="" class="d-block m-auto w-50">
                      <h5 class="text-center mt-2">{{ SBI.titulo }}</h5>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div id="cajaContenidoHistoriaUsuario" style="display: none;" class="card card-outline-secondary text-center">
            <div class="card-content">
              <div class="card-body pt-3">
                <h2 class="mb-3 font-weight-bolder">Historia de Usuario</h2>
                <div class="row w-100">

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Título</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{HU.titulo}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Solicitante</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{HU.solicitante}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Prioridad</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{HU.prioridad}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Riesgo</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{HU.riesgo}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Orden</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{HU.orden}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Estimación</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{HU.estimacion}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label font-weight-bold">Restricción</label>
                      <div class="col-md-9">
                        <textarea rows="4" class="form-control bg-white" readonly [(ngModel)]="HU.restriccion"
                          name="restriccion"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label font-weight-bold">Condición</label>
                      <div class="col-md-9">
                        <textarea rows="4" class="form-control bg-white" readonly [(ngModel)]="HU.condicion"
                          name="condicion"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label font-weight-bold">Descripción</label>
                      <div class="col-md-9">
                        <textarea rows="4" class="form-control bg-white" readonly [(ngModel)]="HU.descripcion"
                          name="descripcion"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="cajaContenidoProductBacklog2" style="display: none;" class="card card-outline-secondary text-center">
            <div class="card-content">
              <div class="card-body pt-3">
                <h2 class="mb-3 font-weight-bolder">Product Backlog</h2>
                <div class="row w-100">

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Título</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{PB.titulo}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Horas</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{PB.horas}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Dias</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{PB.dias}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Prioridad</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{PB.prioridad}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Orden</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{PB.orden}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label font-weight-bold">Descripción</label>
                      <div class="col-md-9">
                        <textarea rows="4" class="form-control bg-white" readonly [(ngModel)]="PB.detalle"
                          name="detalle"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="cajaContenidoSprintBacklogItem" style="display: none;"
            class="card card-outline-secondary text-center">
            <div class="card-content">
              <div class="card-body pt-3">
                <h2 class="mb-3 font-weight-bolder">SprintBacklog Item</h2>
                <div class="row w-100">

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Título</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{SBI.titulo}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Fecha Inicio</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{SBI.fecha_inicio | date:'short' }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Fecha Fin</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{SBI.fecha_fin | date:'short' }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Horas</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{SBI.horas}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Dias</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{SBI.dias}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Prioridad</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{SBI.prioridad}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label font-weight-bold">Orden</label>
                      <div class="col-md-8">
                        <p class="mt-1">{{SBI.orden}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label font-weight-bold">Descripción</label>
                      <div class="col-md-9">
                        <textarea rows="4" class="form-control bg-white" readonly [(ngModel)]="SBI.detalle"
                          name="detalle"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Entregables</h4>
            </div>
            <div class="card-content">
              <div class="card-body table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th>Título</th>
                      <th>Descripción</th>
                      <th>Archivos</th>
                      <th>Tipo de entregable</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let en of ENTREGABLES">
                      <td>{{en.titulo}}</td>
                      <td>{{en.detalle}}</td>
                      <td></td>
                      <td>{{en.tipo}}</td>
                      <td>
                        <a class="primary p-0" (click)="verProductBacklog(en)" data-original-title="" title="">
                          <i class="fa fa-search font-medium-3 mr-2"></i>
                        </a>

                      </td>
                    </tr>


                  </tbody>
                </table>
              </div>
            </div>
          </div>




        </div>
        <div class="modal-footer">
          <button (click)="cerrarModal()" type="button" class="btn btn-danger">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>