import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
// import { Usuario } from '../classes/usuario';
import { Router } from '@angular/router';
// import { UsuarioService } from './service.index';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { UsuarioService } from './usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketServerService {

  public socketStatus = false;
  // public usuario: Usuario = null;
  public usuarioToken = null;

  constructor(
    private http: HttpClient,
    private socket: Socket,
    private router: Router,
    private _usuarioService:UsuarioService,
  ) {
    // this.cargarStorage();
    this.checkStatus();
  }


    checkStatus() {

      this.socket.on('connect', () => {
        console.log('Conectado al servidor');
        this.socketStatus = true;

        // this.loginWS().subscribe();
      });

      this.socket.on('disconnect', () => {
        console.log('Desconectado del servidor');
        this.socketStatus = false;
      });
    }

    
    
// Función para emitir una solicitud
    emit( evento: string, payload?: any, callback?: Function ) {
      // emit('EVENTO', payload, callback?)
      this.socket.emit( evento, payload, callback );
    }
// Función para emitir una escucha
    listen( evento: string ) {
      return this.socket.fromEvent( evento );
    }

    loginWS(idProyecto=0,tipo="") {
      
        if(!this._usuarioService.token)
        {
          console.log("TOKEN INVALIDO PARA SOCKET")
          return
        }
        let tokenNow=this._usuarioService.token

        
          let url1=URL_SERVICIOS+ "login/obtener_dataToken?token="+this._usuarioService.token;
          
          return this.http.get(url1)
          .map((respuestaHttp_1: any) =>
            {
              let idUsuario=respuestaHttp_1.dato.usuario.id
              let email=respuestaHttp_1.dato.usuario.email
              let rolEnSistema=respuestaHttp_1.dato.usuario.rol
              
              return new Promise(  (resolve, reject) => {
                this.emit( 'configurar-usuario', { tokenNow,idUsuario,email,rolEnSistema }, resp => {
                  
                  resolve(true);
        
                });
        
              });
            })
            .catch(err=>{
              
              return err;
              
              
            });

      

    }

    logoutWS() {

      const payload = {
        nombre: 'sin-nombre'
      };

      this.emit('configurar-usuario', payload, () => {} );

    }
}
