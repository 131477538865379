import { Component, DoCheck } from '@angular/core';
import { ModalUsuarioService } from './modal-usuario.service';

import swal from 'sweetalert2';
import { UsuarioService } from 'src/app/services/service.index';

@Component({
  selector: 'app-modal-usuario',
  templateUrl: './modal-usuario.component.html'
})
export class ModalUsuarioComponent implements DoCheck {

  optionCambioPass = 0;

  n_vez = 0;
  n_vez2 = 0;
  USUARIO_DATA =
    {
      "id": "",
      "email": "",
      "password": "",
      "rol": "COLABORADOR",
      "token": "",
    };

  PASSWORD_DATA =
    {
      "token": "",
      "idUsuario": "",
      "role": "",
      "estado": "",
      "new_password": "",
      "new_password2": ""
    };

  constructor(
    public __modalUsuarioService: ModalUsuarioService,
    public __usuarioService: UsuarioService
  ) { }

  ngDoCheck() {

    if (this.__modalUsuarioService.oculto == "" && this.n_vez == 0) {
      this.n_vez = 1;
    }

    if (this.__modalUsuarioService.opcion == "updatePassword" && this.n_vez2 == 0) {
      this.n_vez2 = 1;
      this.PASSWORD_DATA.estado = this.__modalUsuarioService.estado;
      this.PASSWORD_DATA.role = this.__modalUsuarioService.role;
    }

  }

  cerrarModal() {
    this.n_vez = 0;
    this.n_vez2 = 0;

    this.USUARIO_DATA =
    {
      "id": "",
      "email": "",
      "password": "",
      "rol": "COLABORADOR",
      "token": "",
    };

    this.__modalUsuarioService.ocultarModal();
  }

  changeOption() {
    if (this.optionCambioPass == 0) {
      this.optionCambioPass = 1;
    } else {
      this.optionCambioPass = 0;
      this.PASSWORD_DATA.new_password = "";
      this.PASSWORD_DATA.new_password2 = "";
    }
  }

  updatePassword() {

    if (this.optionCambioPass == 1) {
      if (this.PASSWORD_DATA.new_password == "" || this.PASSWORD_DATA.new_password2 == "") {
        swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Ingrese Contraseña.',
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      if (this.PASSWORD_DATA.new_password != this.PASSWORD_DATA.new_password2) {
        swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Las contraseñas deben ser iguales para la validación.',
          showConfirmButton: false,
          timer: 1500
        });

        return;
      }
    }

    this.PASSWORD_DATA.token = this.__usuarioService.token;
    this.PASSWORD_DATA.idUsuario = this.__modalUsuarioService.idUsuario;


    document.getElementById("btn_updatePassword").style.display = "none";

    this.__usuarioService.updatePassword(this.PASSWORD_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.__usuarioService.__refreshUsuarios();
          this.cerrarModal();
          return;
        }
        document.getElementById("btn_updatePassword").style.display = "block";
      });
  }

  agregarUsuario() {

    if (this.USUARIO_DATA.email == "") {
      swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Ingrese e-mail del usuario.',
        showConfirmButton: false,
        timer: 2500
      });
      return;
    }

    if (this.USUARIO_DATA.password == "") {
      swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Ingrese contraseña del usuario.',
        showConfirmButton: false,
        timer: 2500
      });

      return;
    }


    document.getElementById("btn_add_user").style.display = "none";

    this.USUARIO_DATA.token = this.__usuarioService.token;

    this.__usuarioService.crearUsuario(this.USUARIO_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Usuario Creado',
            showConfirmButton: false,
            timer: 4500
          })
          this.__usuarioService.__refreshUsuarios();
          this.cerrarModal();
        } else {

          document.getElementById("btn_add_user").style.display = "block";
          swal.fire({
            position: 'top-end',
            icon: 'error',
            title: '' + resp2.msn,
            showConfirmButton: false,
            timer: 4500
          })

        }


      });
  }

}
