import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  ROL
  email

  logoUrl = 'assets/img/logo.png';
  constructor( public _usuarioService: UsuarioService, public router: Router) { }

  ngOnInit() {
    this._usuarioService.obtenerDataToken(this._usuarioService.token).subscribe((data)=>{
      
      this.ROL=data.usuario.rol
      this.email=data.usuario.email.replace('@apolomultimedia.com', '');
      
  });
  }

}
