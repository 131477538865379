import { Injectable, Output, EventEmitter } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { DetalleUsuario } from 'src/app/models/detalleUsuario.model';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';



import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import swal from 'sweetalert2';
import { Router } from '@angular/router';

import { of } from 'rxjs';



@Injectable({
  providedIn: 'root'
  
})
export class UsuarioService {

  
  usuarioData: Usuario;

  DetalleUsuario: DetalleUsuario;
  token: string;
  token_exp: string;
  

  constructor(
    public http: HttpClient,
    public router: Router
  ) { 
    this.cargarStorage();
    
  }
  
  @Output() refreshUsuarios: EventEmitter<boolean> = new EventEmitter();
  __refreshUsuarios()
  {
    this.refreshUsuarios.emit();
  }

  getUsersAvailablesForProject(data)
   {
    let url=URL_SERVICIOS+ "usuarios/getUsersAvailablesForProject?offset="+data.offset+"&limit="+data.limit+"&idProyecto="+data.idProyecto+"&busqueda="+data.busqueda+"&token="+data.token;

    return this.http.get(url)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("+++++++++++++++++++++++++");
                      console.log(err);
                      console.log("+++++++++++++++++++++++++");
                      return of(err);
                    });
  }

  buscarUsuarioLive(data)
   {
    let url=URL_SERVICIOS+ "usuarios/obtenerUsuariolive?offset="+data.offset+"&limit="+data.limit+"&busqueda="+data.busqueda+"&token="+data.token;

    return this.http.get(url)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("+++++++++++++++++++++++++");
                      console.log(err);
                      console.log("+++++++++++++++++++++++++");
                      return of(err);
                    });
  }

  getUsers(data)
  {
    let url_busqueda_data_usua=URL_SERVICIOS+ "usuarios/getUsuarios?page="+data.page+"&token="+data.token;
// console.log(url_busqueda_data_usua);
    return this.http.get(url_busqueda_data_usua)
              .map((resp:any)=>
              {
                return resp;
              });
  }

  renuevaToken()
  {
    let url=URL_SERVICIOS+ "login/renuevaToken/"+this.token+"/";

    return this.http.get(url)
                  .map((resp: any)=>
                    {
                       this.token=resp[0].Token;
                       this.token_exp=resp[0].Token_expiracion;
                       this.usuarioData=resp[0].usuario;

                       localStorage.setItem("token", this.token);
                       localStorage.setItem("token_exp", this.token_exp);

                      console.log("Token renovado");

                      return true;
                    })
                    .catch(err=>{
                      console.log(err);
                      this.router.navigate(["/login"]);
                      swal.fire("No se pudo renovar el token" , "No fué posible renovar token", "error");
                      return of(err);
                    });
  }

  eliminarUsuario(data_enviar)
  {
    let url = URL_SERVICIOS+"usuarios/eliminarUsuario";
    
     return this.http.post(url, data_enviar)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{

                      if(err)
                      {
                        swal.fire("error", "0", "error");
                      }
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                      
                    });
  }

  estaLogueado()
  {
    let d_token=this.token;

    if(!d_token){
      d_token="0";
    }
    
    let url1=URL_SERVICIOS+ "login/obtener_dataToken?token="+d_token;
    
    return this.http.get(url1)
    .map((resp: any) =>
      {
        return resp.ok;
      })
      .catch(err=>{

        if(err)
        {
          swal.fire("Se produjo un error", "0", "error");
        }
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
        
        
      });
      
  }

  obtenerDataToken(dato_a_desencriptar)
  {

    let url1=URL_SERVICIOS+ "login/obtener_dataToken?token="+dato_a_desencriptar;
    
    return this.http.get(url1)
    .map((resp: any) =>
      {
        return resp.dato;
      })
      .catch(err=>{

        if(err)
        {
          swal.fire("Se produjo un error", "0", "error");
        }
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
        
        
      });
  }

  cargarStorage()
  {
    if(localStorage.getItem("token"))
    {
      this.token= localStorage.getItem("token");
      this.token_exp= localStorage.getItem("token_exp");
    }else{
      this.token= "";
      this.token_exp= "";
      this.usuarioData= null;

      this.logout();
    }
  }

  crearUsuario(usuario: Usuario)
  {
    let url = URL_SERVICIOS+"usuarios/agregarUsuario";
    
    
     return this.http.post(url, usuario)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                      
                    });
  }

  updatePassword(usuario)
  {
    let url = URL_SERVICIOS+"usuarios/updatePassword";
    
    
     return this.http.post(url, usuario)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                      
                    });
  }
  

  buscarUsuario(data)
  {
    let url=URL_SERVICIOS+ "usuarios/obtenerUsuarioPorEmail?email="+data.email;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  recuperarClave(email_recuperar)
  {
    let url_email_recuperar=URL_SERVICIOS+ "usuarios/cambiarClave/"+email_recuperar+"/";
    
    
    return this.http.get(url_email_recuperar)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        if(err)
        {
          swal.fire("Se produjo un error", "error al cambiar clave", "error");
        }
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
        
        
      });
  }

  cambiarContrasena(modelContrasenas)
  {
    let url= URL_SERVICIOS+ "usuarios/cambiarContrasena";

    return this.http.post(url, modelContrasenas)
        .map((resp: any) =>
          {
            return resp;
          })
          .catch(err=>{            
            console.log("----------------");
            console.log(err);
            console.log("----------------");
            return of(err);
          });
  }
  
  login(usuario, recordar: boolean=false)
  {
    if(recordar)
    {
      localStorage.setItem("email", usuario.email);
    }else{
      localStorage.removeItem("email");
    }

    let url= URL_SERVICIOS+ "login/";

    
    return this.http.post(url, usuario)
        .map((resp: any) =>
          {
            if(!resp.ok)
            {
              return resp;
            }
            
            this.guardarStorage(resp.Token,resp.Token_expiracion,resp.usuario);

            return resp;
          })
          .catch(err=>{
            
            console.log("----------------");
            console.log(err);
            console.log("----------------");
            return of(err);
          });
  }

  guardarStorage(token: string,token_exp:string,usuario)
  {

    localStorage.setItem("token", token);
    localStorage.setItem("token_exp", token_exp);
    
    this.token=token;
    this.token_exp=token_exp;
    this.usuarioData=usuario;
  }

  logout()
  {
    
    this.token="";
    this.token_exp="";
    this.usuarioData=null;

    
    localStorage.removeItem("token");
    localStorage.removeItem("token_exp");

    this.router.navigate(["/login"]);
  
  
  }


  guardarDetalleUsuario(detalleUsuario)
  {
    let url = URL_SERVICIOS+"detalleUsuario/guardarDetalleUsuario";
    
    return this.http.post(url, detalleUsuario)
        .map((resp: any) =>
          {
            return resp;
          })
          .catch(err=>{
            console.log("+++++++++++++++++++++++++");
            console.log(err);
            console.log("+++++++++++++++++++++++++");
            return of(err);
          });
  }

  actualizarDetalleUsuario(detalleUsuario)
  {
    let url = URL_SERVICIOS+"detalleUsuario/actualizarDetalleUsuario";
    
    return this.http.post(url, detalleUsuario)
        .map((resp: any) =>
          {
            return resp;
          })
          .catch(err=>{
            console.log("+++++++++++++++++++++++++");
            console.log(err);
            console.log("+++++++++++++++++++++++++");
            return of(err);
          });
  }


  obtenerDetalleUsuario(data)
  {
    let url=URL_SERVICIOS+ "detalleUsuario/obtenerPorIdUsuario?token="+data.token;

    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  



}
