import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import swal from 'sweetalert2';

import { UsuarioService } from '../services/service.index';
import { Usuario } from '../models/usuario.model';
import { Router } from '@angular/router';

declare function init_plugins();

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  forma: FormGroup;

  constructor(
    public _usuarioService: UsuarioService,
    public router: Router
  ) { }

  ngOnInit() {
    init_plugins();


    this.forma = new FormGroup(
      {
        correo: new FormControl(null, [Validators.required,Validators.email]),
        password: new FormControl(null, [Validators.required]),
        confirm_password: new FormControl(null, [Validators.required]),
        condiciones: new FormControl(false),
      },
      { validators: this.sonIguales("password", "confirm_password")}
    );


  }

  sonIguales(campo1:string, campo2:string)
  {
    return (group: FormGroup)=>{

      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;

      if(pass1 === pass2)
      {
        return null;
      }

      return { sonIguales: true };
    }
  }

  registrarUsuario()
  {
    if(this.forma.invalid)
    {
      swal.fire("Importante", "Las claves deben ser iguales", "error");
      return;
    }
    
    if(!this.forma.value.condiciones)
    {
      swal.fire("Importante", "Debe de aceptar las condiciones", "error");

      return;
    }
    
    let usuario = new Usuario(
      this.forma.value.correo,
      this.forma.value.password
    );

    this._usuarioService.crearUsuario(usuario)
              .subscribe((resp:any)=>
              {
                if(resp.ok)
                {
                  this.router.navigate(["/login"]);
                }
                
              });
    
  }

}
