import { Injectable, Output, EventEmitter } from '@angular/core';

import { URL_SERVICIOS } from 'src/app/config/config';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { WebSocketServerService } from './web-socket-server.service';

@Injectable({
  providedIn: 'root'
})
export class SprintPlanningMeetingSocketService {

  constructor(
    public http: HttpClient,
    public wsService: WebSocketServerService,
  ) { }

  @Output() refreshParticipantes: EventEmitter<boolean> = new EventEmitter();
  __refreshParticipantes()
  {
    this.refreshParticipantes.emit();
  }

  emitirStopStreeming(idProyecto,tokenUser)
  {
    console.log("emitirStopStreeming")
    let data={"idProyecto":idProyecto,"token":tokenUser,"tipo":"sprintPlanningMeeting"}
    this.wsService.emit('stopStreeming', data);
  }

  onStopStreeming()
  {
    return this.wsService.listen( 'stopStreeming' );
  }

  onAnswer()
  {
    return this.wsService.listen( 'answer' );
  }

  onCandidate()
  {
    return this.wsService.listen( 'candidate' );
  }

  onOffer()
  {
    return this.wsService.listen( 'offer' );
  }

  emitiAnswer(answer)
  {
    this.wsService.emit('answer', answer);
  }

  solicitarParticipantesEnSalaSocket()
  {
    this.wsService.emit( 'obtener-usuarios');
  }

  emitirCandidato(candidate,idProyecto,tokenNow)
  {
    let data={"candidate":candidate,"idProyecto":idProyecto,"token":tokenNow,"tipo":"sprintPlanningMeeting"}
    this.wsService.emit('candidate', data);
  }

  emitirOffer(offer)
  {
    this.wsService.emit('offer', offer);
  }

  obtenerParticipantesEnSalaSocket()
  {
    return this.wsService.listen( 'usuarios-activos' );
  }
  
  existeUsuarioInSalaSprintMeeting(data)
  {
    let url=URL_SERVICIOS+ "sprintPlanningMeeting/existeUsuarioInSalaSprintMeeting?token="+data.token+"&idProyecto="+data.idProyecto;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  buscarUsuarioParaSala(data)
  {
    let url=URL_SERVICIOS+ "sprintPlanningMeeting/buscarUsuarioParaSala?token="+data.token+"&idProyecto="+data.idProyecto+"&busqueda="+data.busqueda;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }

  agregarParticipante(data)
  {
    let url=URL_SERVICIOS+ "sprintPlanningMeeting/agregarParticipante";
    
     return this.http.post(url, data)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                    });
  }



  getParticipantesSprintPlanningMeeting(data)
  {
    let url=URL_SERVICIOS+ "sprintPlanningMeeting/getParticipantesSprintPlanningMeeting?token="+data.token+"&idProyecto="+data.idProyecto;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{

        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }


  eliminarParticipante(data_enviar)
  {
    let url = URL_SERVICIOS+"sprintPlanningMeeting/eliminarParticipante";
    
     return this.http.post(url, data_enviar)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                    });
  }
}
