import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';
import { WebSocketServerService } from './web-socket-server.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(
    public http: HttpClient,
    public wsService: WebSocketServerService,
  ) {}

  getByIdUsuarioReceptor(data)
  {
    let url=URL_SERVICIOS+ "notificacion/getByIdUsuarioReceptor?token="+data.token;
    
    return this.http.get(url)
    .map((resp: any) =>
      {
        return resp;
      })
      .catch(err=>{
        console.log("----------------");
        console.log(err);
        console.log("----------------");
        
        return of(err);
      });
  }


  eliminarNotificacion(data_enviar)
  {
    let url = URL_SERVICIOS+"notificacion/eliminarNotificacion";
    
     return this.http.post(url, data_enviar)
                  .map((resp: any)=>
                    {
                      return resp;
                    })
                    .catch(err=>{
                      console.log("----------------");
                      console.log(err);
                      console.log("----------------");
                      
                      return of(err);
                      
                      
                    });
  }


  emitirNotificaciones(idUsuario)
  {
    let data={"idUsuarioReceptor":idUsuario}
    this.wsService.emit('emitirConsultaNotificaciones', data);
  }

  listenNotificaciones()
  {
    return this.wsService.listen( 'emitirConsultaNotificaciones' );
  }
}
