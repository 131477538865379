import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { ModalHistoriaUsuarioService } from '../modal-historia-usuario/modal-historia-usuario.service';

@Component({
  selector: 'app-vista-historia-usuario-base',
  templateUrl: './vista-historia-usuario-base.component.html'
})
export class VistaHistoriaUsuarioBaseComponent implements DoCheck {

  @Input() modo: string;
  @Input() historiasDeUsuarios = [];
  @Input() jsonHU = {
    "titulo": "",
    "id": "",
    "solicitante": "",
    "riesgo": "",
    "restriccion": "",
    "prioridad": "",
    "idUsuarioCreador": "",
    "idProyecto": "",
    "estimacion": "",
    "estado": "",
    "descripcion": "",
    "condicion": "",
  };
  @Input() estado: boolean = false;

  @Input() idChooseHU: number = 0;

  @Input() idProyecto: number;

  @Output() emitEstablecer = new EventEmitter<any>();

  n_vez = 0;
  idChooseHUBase = 0;

  constructor(
    public __modalHistoriaUsuarioService: ModalHistoriaUsuarioService,
  ) { }

  ngDoCheck() {

    if (this.idChooseHU != 0 && this.n_vez == 0) {
      let element: HTMLElement = document.getElementById('btnEscogerHistoriaUsuario_' + this.idChooseHU) as HTMLElement;
      element.click();
      this.idChooseHUBase = this.idChooseHU;
      this.n_vez = 1;
    } else if (this.idChooseHU != this.idChooseHUBase && this.idChooseHU != 0) {
      let element: HTMLElement = document.getElementById('btnEscogerHistoriaUsuario_' + this.idChooseHU) as HTMLElement;
      element.click();
      this.idChooseHUBase = this.idChooseHU;
    } else if (this.idChooseHU == 0) {
      this.n_vez = 0;
    }

  }

  emitirEstablecer(hu) {
    this.emitEstablecer.emit(hu);
  }

  modalUpdateHU(hu) {
    if (this.idProyecto) {
      this.__modalHistoriaUsuarioService.mostrarModal("update", this.idProyecto, hu);
    } else {
      console.log("Falta el idProyecto")
    }
  }

}
