import { Component, DoCheck } from '@angular/core';
import { VistaSprintBackLogItemService } from './vista-sprint-back-log-item.service';
import { VistaCuentaRegresivaService } from '../vista-cuenta-regresiva/vista-cuenta-regresiva.service';
import { UsuarioService } from 'src/app/services/service.index';
import { ModalEntregableService } from '../modal-entregable/modal-entregable.service';

@Component({
  selector: 'app-vista-sprint-back-log-item',
  templateUrl: './vista-sprint-back-log-item.component.html'
})
export class VistaSprintBackLogItemComponent implements DoCheck {

  ENTREGABLES: any = []

  SBI: any = {
    "id": "", "idUsuarioCreador": "", "detalle": "", "dias": "", "estado": "", "fecha_fin": "", "fecha_inicio": "", "horas": "", "orden": "", "prioridad": "", "titulo": ""
  }

  HU: any = {
    "id": "", "condicion": "", "descripcion": "", "estado": "", "estimacion": "", "orden": "", "prioridad": "", "restriccion": "", "riesgo": "", "solicitante": "", "titulo": ""
  }

  PB: any = {
    "id": "", "detalle": "", "dias": "", "estado": "", "horas": "", "orden": "", "prioridad": "", "titulo": ""
  }
  n_vez = 0

  constructor(
    public __VistaSprintBackLogItemService: VistaSprintBackLogItemService,
    public __VistaCuentaRegresivaService: VistaCuentaRegresivaService,
    public _usuarioService: UsuarioService,
    public _ModalEntregableService: ModalEntregableService,
  ) { }

  ngDoCheck() {

    if (this.__VistaSprintBackLogItemService.oculto == "" && this.n_vez == 0) {
      this.n_vez = 1;
      this.__VistaCuentaRegresivaService.mostrarModal(this.__VistaSprintBackLogItemService.data.SBI_fecha_fin);

      this.getSprintBacklogItemById(this.__VistaSprintBackLogItemService.data)

      setTimeout(() => this.getEntregableByIdSprintItem(this.SBI["id"]), 400);
    }
  }


  getSprintBacklogItemById(idSprintBacklogItem) {
    this.SBI["id"] = idSprintBacklogItem.SBI_id;
    this.SBI["idUsuarioCreador"] = idSprintBacklogItem.SBI_idUsuarioCreador;
    this.SBI["detalle"] = idSprintBacklogItem.SBI_detalle;
    this.SBI["dias"] = idSprintBacklogItem.SBI_dias;
    this.SBI["estado"] = idSprintBacklogItem.SBI_estado;
    this.SBI["fecha_fin"] = idSprintBacklogItem.SBI_fecha_fin;
    this.SBI["fecha_inicio"] = idSprintBacklogItem.SBI_fecha_inicio;
    this.SBI["horas"] = idSprintBacklogItem.SBI_horas;
    this.SBI["orden"] = idSprintBacklogItem.SBI_orden;
    this.SBI["prioridad"] = idSprintBacklogItem.SBI_prioridad;
    this.SBI["titulo"] = idSprintBacklogItem.SBI_titulo;

    this.HU["id"] = idSprintBacklogItem.HU_id;
    this.HU["condicion"] = idSprintBacklogItem.HU_condicion;
    this.HU["descripcion"] = idSprintBacklogItem.HU_descripcion;
    this.HU["estado"] = idSprintBacklogItem.HU_estado;
    this.HU["estimacion"] = idSprintBacklogItem.HU_estimacion;
    this.HU["orden"] = idSprintBacklogItem.HU_orden;
    this.HU["prioridad"] = idSprintBacklogItem.HU_prioridad;
    this.HU["restriccion"] = idSprintBacklogItem.HU_restriccion;
    this.HU["riesgo"] = idSprintBacklogItem.HU_riesgo;
    this.HU["solicitante"] = idSprintBacklogItem.HU_solicitante;
    this.HU["titulo"] = idSprintBacklogItem.HU_titulo;

    this.PB["id"] = idSprintBacklogItem.PB_id;
    this.PB["detalle"] = idSprintBacklogItem.PB_detalle;
    this.PB["dias"] = idSprintBacklogItem.PB_dias;
    this.PB["estado"] = idSprintBacklogItem.PB_estado;
    this.PB["horas"] = idSprintBacklogItem.PB_horas;
    this.PB["orden"] = idSprintBacklogItem.PB_orden;
    this.PB["prioridad"] = idSprintBacklogItem.PB_prioridad;
    this.PB["titulo"] = idSprintBacklogItem.PB_titulo;
  }

  getEntregableByIdSprintItem(idSprintBacklogItem) {
    let data = {
      "idSprintBacklogItem": idSprintBacklogItem,
      "token": this._usuarioService.token
    }
    this._ModalEntregableService.getEntregableByIdSprintItem(data)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.ENTREGABLES = resp2.data;
        }
      });
  }

  toggleBox(tipo) {
    let element

    if (tipo == "HU") {
      let element = document.getElementById("cajaContenidoHistoriaUsuario");
      if (element.style.display === 'block') {
        element.style.display = 'none'
      } else {
        document.getElementById("cajaContenidoSprintBacklogItem").style.display = 'none'
        document.getElementById("cajaContenidoProductBacklog2").style.display = 'none'
        element.style.display = 'block'
      }
    } else if (tipo == "PB") {
      let element = document.getElementById("cajaContenidoProductBacklog2");
      if (element.style.display === 'block') {
        element.style.display = 'none'
      } else {
        document.getElementById("cajaContenidoSprintBacklogItem").style.display = 'none'
        document.getElementById("cajaContenidoHistoriaUsuario").style.display = 'none'
        element.style.display = 'block'
      }
    } else if (tipo == "SBI") {
      let element = document.getElementById("cajaContenidoSprintBacklogItem");
      if (element.style.display === 'block') {
        element.style.display = 'none'
      } else {
        document.getElementById("cajaContenidoProductBacklog2").style.display = 'none'
        document.getElementById("cajaContenidoHistoriaUsuario").style.display = 'none'
        element.style.display = 'block'
      }
    }


  }

  cerrarModal() {
    this.n_vez = 0;

    this.__VistaSprintBackLogItemService.ocultarModal();
    this.__VistaCuentaRegresivaService.__cerrarCuentaRegresiva();
  }

  verProductBacklog(e) {

  }

}
