<div class="fondo-negro animated fadeIn" [ngClass]="_gestionRolesService">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" style="overflow: auto;height: 660px;">
        <div class="modal-header">
          <h2 class="modal-title font-weight-bolder">Gestión de actores</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body" align="center">

          <div class="row">

            <div class="col-xl-4 col-lg-6 col-12" *ngFor="let uR of usuarioRolesData">
              <div class="card">
                <div class="card-content">

                  <div class="card-body p-2">
                    <i class="ft-user primary font-large-2"></i>
                    <p class="card-text text-truncate">{{ uR.email}} </p>
                    <p class="card-text mb-0">{{ uR.rolEnProyecto}} </p>
                    <button type="button" *ngIf="uR.rolEnProyecto!=='PRODUCT_OWNER'"
                      (click)="eliminarUsuarioDelProyecto(uR)" class="btn btn-danger mb-0 float-right">
                      <i class="ft-trash"></i>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="input-group">
            <input type="text" class="form-control" (keyup)="buscarUsuario(usuarioBuscar)" [(ngModel)]="usuarioBuscar"
              placeholder="Busca el usuario" aria-describedby="button-addon2">
            <div class="input-group-append">

            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table class="table table-striped table-bordered javascript-sourced dataTable no-footer"
                id="DataTables_Table_2" role="grid" aria-describedby="DataTables_Table_2_info">
                <thead>
                  <tr role="row">
                    <th class="sorting_asc" style="width: 176.75px;">Email</th>
                    <th class="sorting" style="width: 102.75px;">Rol</th>
                    <th class="sorting" style="width: 102.75px;"></th>
                  </tr>
                </thead>
                <tbody>

                  <ng-container *ngFor="let usu of dataUsuarioBuscado">
                    <tr role="row" class="odd">
                      <td>{{usu.email}}</td>
                      <td>
                        <select class="form-control" id="rolUsu_{{usu.idUsuario}}">
                          <option *ngIf="eScrumMaster===0" value="SCRUM_MASTER">SCRUM MASTER</option>
                          <option value="TEAM_DEV">TEAM DEV</option>
                        </select>
                      </td>
                      <td>
                        <button (click)="agregarUsuarioAlProyecto(usu)"
                          class="btn btn-success btn-raised">Agregar</button>
                      </td>
                    </tr>
                  </ng-container>



                </tbody>
              </table>

              <nav>
                <ul class="pagination">
                  <li class="page-item">
                    <ng-container *ngIf="pagination.anterior === true; then loggedIn; else loggedOut">
                    </ng-container>
                    <ng-template #loggedIn>
                      <a class="page-link" (click)="cambiar_Pagina(pagination.actual - 1)">Ant</a>
                    </ng-template>
                    <ng-template #loggedOut>
                      <a class="page-link">Ant</a>
                    </ng-template>
                  </li>

                  <li class="page-item">
                    <a class="page-link">{{ pagination.actual }}</a>
                  </li>

                  <li class="page-item">
                    <ng-container *ngIf="pagination.siguiente === true; then loggedIn2; else loggedOut2">
                    </ng-container>
                    <ng-template #loggedIn2>
                      <a class="page-link" (click)="cambiar_Pagina(pagination.actual + 1)">Sig</a>
                    </ng-template>
                    <ng-template #loggedOut2>
                      <a class="page-link">Sig</a>
                    </ng-template>
                  </li>
                </ul>
              </nav>
            </div>
          </div>





        </div>
        <div class="modal-footer">
          <button (click)="cerrarModal()" type="button" class="btn btn-danger">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>