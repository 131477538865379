import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import { Location } from '@angular/common';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // Nombre="Aaron";

  Notificacion = []
  NotificacionQuantity = 0


  headerPegado = 0;

  constructor(
    public _usuarioService: UsuarioService,
    public _NotificacionesService: NotificacionesService,
    private _location: Location,
    public router: Router,
  ) { }



  ngOnInit() {

    setTimeout(() => this.getByIdUsuarioReceptor(), 1000);


    this._NotificacionesService.listenNotificaciones().subscribe((msg: any) => {
      this.getByIdUsuarioReceptor()
    });
  }


  backClicked() {
    this._location.back();
  }


  getByIdUsuarioReceptor() {
    let data = {
      "token": this._usuarioService.token
    }

    this._NotificacionesService.getByIdUsuarioReceptor(data)
      .subscribe((resp2: any) => {

        if (resp2.ok) {
          let dataGet = resp2.data
          this.Notificacion = dataGet
          this.NotificacionQuantity = dataGet.length
        }
      });
  }

  redigirir(id, url) {
    let data = {
      "token": this._usuarioService.token,
      "idNotificacion": id,
    }

    this._NotificacionesService.eliminarNotificacion(data)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.getByIdUsuarioReceptor()
          this.router.navigate([url]);
        }
      });
  }



}
