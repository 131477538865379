import { Component, OnDestroy, OnInit } from '@angular/core';
import { WebSocketServerService } from '../services/web-socket-server.service';

declare function init_plugins();

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit, OnDestroy {

  constructor(
    public wsService: WebSocketServerService,
  ) { }

  ngOnInit() {
    init_plugins();

    this.wsService.loginWS(0, "GENERAL").subscribe();
  }

  ngOnDestroy() {
    this.wsService.logoutWS();
  }

}
