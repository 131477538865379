<!-- ////////////////////////////////////////////////////////////////////////////-->
<div class="wrapper">
    
    <app-sidebar></app-sidebar>

    <!-- Navbar (Header) Starts-->
    <app-header></app-header>
    <!-- Navbar (Header) Ends-->


    <div class="main-panel">
        <router-outlet></router-outlet>
        
        
    </div>
  </div>
  <!-- ////////////////////////////////////////////////////////////////////////////   -->

  <app-cargando></app-cargando>

  <app-modal-gestion-roles></app-modal-gestion-roles>

  <app-modal-crear-proyecto></app-modal-crear-proyecto>

  <app-modal-usuario></app-modal-usuario>

  <app-modal-historia-usuario></app-modal-historia-usuario>

  <app-modal-product-backlog></app-modal-product-backlog>
  
  <app-sprint-backlog-item></app-sprint-backlog-item>
  
  <app-vista-sprint-back-log-item></app-vista-sprint-back-log-item>

  <app-modal-participante></app-modal-participante>
  <app-modal-entregable></app-modal-entregable>

  <app-vista-imagen></app-vista-imagen>
  
  <app-modal-comentarios></app-modal-comentarios>