<div class="fondo-negro animated fadeIn" [ngClass]="_ModalEntregableService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content" style="overflow: auto;height: 92vh;">
            <div class="modal-header">
              <h2 class="modal-title">Entregable</h2>
              
              <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
                <span aria-hidden="true" >&times;</span>
              </button>
            </div>
            
            <div class="modal-body" align="center">
                
              <div class="row">

                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="horizontal-form-4">Título</label>
                        <div class="col-md-9">
                        <input [className]="ENTREGABLEERROR.titulo !== '' ? 'form-control border-danger' : 'form-control'" maxlength="250" type="text" class="form-control square" [(ngModel)]="ENTREGABLE.titulo" name="titulo">
                        <p class="text-danger">{{ENTREGABLEERROR.titulo}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="horizontal-form-4">Tipo de entregable</label>
                        <div class="col-md-9">
                            <select class="form-control" [(ngModel)]="ENTREGABLE.tipoEntregable" name="tipoEntregable">
                                <option value="PFE">Punto de función entregado</option>
                                <option value="PFD">Punto de función desarrollado</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="horizontal-form-4">Detalle</label>
                        <div class="col-md-9">
                            <textarea [className]="ENTREGABLEERROR.detalle !== '' ? 'form-control border-danger' : 'form-control'" maxlength="1495" rows="10" class="form-control" [(ngModel)]="ENTREGABLE.detalle" name="detalle"></textarea>
                            <p class="text-danger">{{ENTREGABLEERROR.detalle}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="horizontal-form-4">Subir imagen</label>
                        <div class="col-md-9">

                            <label for="file-upload" class="custom-file-upload" style="border: 1px solid #ccc;display: inline-block;padding: 10px 30px;
                            cursor: pointer;float:left;">
                                <i class="fa fa-cloud-upload"></i> Subir imagen
                            </label>
                            <!-- <input style="display:none;" id="file-upload" type="file"/> -->
                            <input id="file-upload" style="display:none;" type="file" name="file" (change)="onSelect($event)" accept="image/png, image/jpeg, image/jpg">

                            <!-- 
                            <ngx-dropzone (change)="onSelect($event)" [accept]="'image/*'">
                                <ngx-dropzone-label>Drop it, baby!</ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                                    <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                            </ngx-dropzone> -->
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label" for="horizontal-form-4">imagenes</label>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-xl-3 col-lg-6 col-12" *ngFor="let img of IMAGENES">
                                    <img class="w-100 border" src="{{ PATH_ROOT }}{{ img.nombreBackend }}{{ img.extension }}" alt="">
                                    <button type="button" (click)="eliminarImagen(img)" class="btn btn-danger mb-0 float-right" style="position: absolute;right: 18px;bottom: 0px;">
                                        <i class="ft-trash"></i>
                                      </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


              </div>
              

              
                

            </div>
            <div class="modal-footer">

                <button (click)="cerrarModal()" type="button" class="btn btn-danger">Cerrar</button>
                <button (click)="actualizarEntregable()" type="button" class="btn btn-primary float-right m-1">Actualizar</button>
            </div>
          </div>
        </div>
    </div>
  </div>