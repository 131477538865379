import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalComentariosService {

   public oculto="oculto";
   public type="";
   public idEntregable=0;
   public idProyecto=0;
 
   constructor(
     public http: HttpClient
   ) { }
 
 
   // @Output() setDataChat: EventEmitter<boolean> = new EventEmitter();
   // __setDataChat(dataForChat,typeOf)
   // {
   //   this.setDataChat.emit(dataForChat);
   //   this.type=typeOf;
   // }
 
  //  @Output() listenOpen: EventEmitter<boolean> = new EventEmitter();
  //  __listenOpen()
  //  {
  //    this.listenOpen.emit();
  //  }
 
   ocultarModal()
   {
     this.oculto="oculto";
     this.type="";
     this.idEntregable=0;
     this.idProyecto=0;
   }
   mostrarModal(type,idEntregable,idProyecto=0)
   {
     this.oculto="";
     
     this.type=type;
     this.idEntregable=idEntregable;
     this.idProyecto=idProyecto;
   }
 
   
   sendComment(data)
   {
     let url= URL_SERVICIOS+ "comentariosESBI/sendComment";
     
     return this.http.post(url, data)
         .map((resp: any) =>
           {
             return resp;
           })
           .catch(err=>{
             console.log("+++++++++++++++++++++++++");
             console.log(err);
             console.log("+++++++++++++++++++++++++");
             return of(err);
           });
   }
 
   getComentariosEntregableByESBI(data)
   {
     // data.idProyecto="";
 
     let url=URL_SERVICIOS+ "comentariosESBI/getByIdESBI?idESBI="+data.idESBI+"&token="+data.token;
     
     
     return this.http.get(url)
     .map((resp: any) =>
       {
         return resp;
       })
       .catch(err=>{
 
         console.log("----------------");
         console.log(err);
         console.log("----------------");
         
         return of(err);
       });
   }
}
