import { Component, DoCheck } from '@angular/core';

import { UsuarioService, CargandoService, HistoriaUsuarioService, ProductBacklogService } from 'src/app/services/service.index';
import { ModalProductBacklogService } from './modal-product-backlog.service';

@Component({
  selector: 'app-modal-product-backlog',
  templateUrl: './modal-product-backlog.component.html'
})
export class ModalProductBacklogComponent implements DoCheck {

  n_vez = 0;
  n_vez2 = 0;
  historiaUsuario = [];

  idChooseHU = 0

  estadoMostrarFormHU = false;

  jsonHU = {
    "titulo": "",
    "id": "",
    "solicitante": "",
    "riesgo": "",
    "restriccion": "",
    "prioridad": "",
    "idUsuarioCreador": "",
    "idProyecto": "",
    "estimacion": "",
    "estado": "",
    "descripcion": "",
    "condicion": "",
  }

  jsonProductBacklog = {
    "titulo": "",
    "horas": "1",
    "dias": "0",
    "prioridad": "ALTA",
    "orden": "1",
    "estado": "1",
    "detalle": "",

    "idHistoriaUsuario": "",
    "idUsuarioCreador": "",
    "idProyecto": 0,
    "idProducBacklog": 0,
    "token": "",
  }

  productBacklogERROR = {
    "HU": "",
    "titulo": "",
    "horas": "",
    "dias": "",
    "prioridad": "",
    "orden": "",
    "estado": "",
    "detalle": "",
  }

  constructor(
    public __modalProductBacklogService: ModalProductBacklogService,
    public _usuarioService: UsuarioService,
    public _modalCargando: CargandoService,
    public _historiaUsuarioService: HistoriaUsuarioService,
    public _ProductBacklogService: ProductBacklogService,
  ) { }

  ngDoCheck() {

    if (this.__modalProductBacklogService.oculto == "" && this.n_vez == 0) {
      this.n_vez = 1;
      this.getHistoriaUsuario();
    }

    if (this.__modalProductBacklogService.oculto == "" && this.n_vez2 == 0) {
      if (this.__modalProductBacklogService.type == "update" || this.__modalProductBacklogService.type == "ver") {
        this.n_vez2 = 1;

        this.jsonProductBacklog.idProducBacklog = this.__modalProductBacklogService.productBacklog.id;
        this.jsonProductBacklog.idUsuarioCreador = this.__modalProductBacklogService.productBacklog.idUsuarioCreador;
        this.jsonProductBacklog.idProyecto = this.__modalProductBacklogService.productBacklog.idProyecto;
        this.jsonProductBacklog.idHistoriaUsuario = this.__modalProductBacklogService.productBacklog.idHistoriaUsuario;
        this.jsonProductBacklog.titulo = this.__modalProductBacklogService.productBacklog.titulo;
        this.jsonProductBacklog.detalle = this.__modalProductBacklogService.productBacklog.detalle;
        this.jsonProductBacklog.horas = this.__modalProductBacklogService.productBacklog.horas;
        this.jsonProductBacklog.dias = this.__modalProductBacklogService.productBacklog.dias;
        this.jsonProductBacklog.prioridad = this.__modalProductBacklogService.productBacklog.prioridad;
        this.jsonProductBacklog.orden = this.__modalProductBacklogService.productBacklog.orden;
        this.jsonProductBacklog.estado = this.__modalProductBacklogService.productBacklog.estado;

      }

      if (this.__modalProductBacklogService.type == "update") {
        setTimeout(() => {
          this.idChooseHU = parseInt(this.__modalProductBacklogService.productBacklog.idHistoriaUsuario)
        }, 500)
      }
    }

  }

  validarCampo(campo) {
    if (campo == "horas") {
      if (parseInt(this.jsonProductBacklog.horas) > 100) {
        this.productBacklogERROR.horas = "Este campo no puede tener un número mayor a 100";
        this.jsonProductBacklog.horas = "100"
        return;
      } else if (parseInt(this.jsonProductBacklog.horas) < 1) {
        this.productBacklogERROR.horas = "Este campo no puede tener un número menor a 1";
        this.jsonProductBacklog.horas = "1"
        return;
      } else {
        this.limpiar();
      }

    }

    if (campo == "dias") {
      if (parseInt(this.jsonProductBacklog.dias) > 100) {
        this.productBacklogERROR.dias = "Este campo no puede tener un número mayor a 100";
        this.jsonProductBacklog.dias = "100"
        return;
      } else if (parseInt(this.jsonProductBacklog.dias) < 1) {
        this.productBacklogERROR.dias = "Este campo no puede tener un número menor a 1";
        this.jsonProductBacklog.dias = "1"
        return;
      } else {
        this.limpiar();
      }

    }

    if (campo == "orden") {
      if (parseInt(this.jsonProductBacklog.orden) > 100) {
        this.productBacklogERROR.orden = "Este campo no puede tener un número mayor a 100";
        this.jsonProductBacklog.orden = "100"
        return;
      } else if (parseInt(this.jsonProductBacklog.orden) < 1) {
        this.productBacklogERROR.orden = "Este campo no puede tener un número menor a 1";
        this.jsonProductBacklog.orden = "1"
        return;
      } else {
        this.limpiar();
      }

    }
  }

  cerrarModal() {
    this.n_vez = 0;
    this.n_vez2 = 0;
    this.idChooseHU = 0;
    this.estadoMostrarFormHU = false;

    this.historiaUsuario = [];

    this.jsonHU = {
      "titulo": "",
      "id": "",
      "solicitante": "",
      "riesgo": "",
      "restriccion": "",
      "prioridad": "",
      "idUsuarioCreador": "",
      "idProyecto": "",
      "estimacion": "",
      "estado": "",
      "descripcion": "",
      "condicion": "",
    }

    this.jsonProductBacklog = {
      "titulo": "",
      "horas": "1",
      "dias": "0",
      "prioridad": "ALTA",
      "orden": "1",
      "estado": "1",
      "detalle": "",

      "idHistoriaUsuario": "",
      "idUsuarioCreador": "",
      "idProyecto": 0,
      "idProducBacklog": 0,
      "token": "",
    }


    this.limpiar();


    this.__modalProductBacklogService.ocultarModal();
  }

  limpiar() {
    this.productBacklogERROR = {
      "HU": "",
      "titulo": "",
      "horas": "",
      "dias": "",
      "prioridad": "",
      "orden": "",
      "estado": "",
      "detalle": "",
    }
  }

  escogerHistoriaUsuario(data) {
    this.jsonHU.id = data.id;
    this.jsonHU.titulo = data.titulo;
    this.jsonHU.solicitante = data.solicitante;
    this.jsonHU.riesgo = data.riesgo;
    this.jsonHU.restriccion = data.restriccion;
    this.jsonHU.prioridad = data.prioridad;
    this.jsonHU.idUsuarioCreador = data.idUsuarioCreador;
    this.jsonHU.idProyecto = data.idProyecto;
    this.jsonHU.estimacion = data.estimacion;
    this.jsonHU.estado = data.estado;
    this.jsonHU.descripcion = data.descripcion;
    this.jsonHU.condicion = data.condicion;

    this.jsonProductBacklog.idHistoriaUsuario = data.id;
  }

  agregarPB() {

    if (this.jsonHU.id == "") { this.productBacklogERROR.HU = "Este campo no puede estar vacio, seleccione una HU"; return; } this.limpiar();
    if (this.jsonProductBacklog.titulo == "") { this.productBacklogERROR.titulo = "Este campo no puede estar vacio"; return; } this.limpiar();

    this.jsonProductBacklog.token = this._usuarioService.token;
    this.jsonProductBacklog.idProyecto = this.__modalProductBacklogService.idProyecto;

    this._modalCargando.mostrarAnimacion();
    this._ProductBacklogService.agregarPB(this.jsonProductBacklog)
      .subscribe((resp2: any) => {
        this._modalCargando.ocultarAnimacion();

        this._ProductBacklogService.__refreshProductBacklog();
        this.cerrarModal();
      });

  }

  getHistoriaUsuario() {
    let dataEnviar = {
      "idProyecto": this.__modalProductBacklogService.idProyecto,
      "busqueda": "",
      "token": this._usuarioService.token
    }

    this._historiaUsuarioService.getHistoriaUsuario(dataEnviar)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.historiaUsuario = respuesta.data;
        }
      });
  }

  updatePB() {

    this.jsonProductBacklog.token = this._usuarioService.token;

    this._ProductBacklogService.updatePB(this.jsonProductBacklog)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this._ProductBacklogService.__refreshProductBacklog();
          this.cerrarModal();
          return;
        }
        document.getElementById("btn_updatePassword").style.display = "block";
      });
  }

}
