import { Component, DoCheck } from '@angular/core';
import { UsuarioService, HistoriaUsuarioService, CargandoService, RolesProyectoService } from 'src/app/services/service.index';
import { ModalHistoriaUsuarioService } from './modal-historia-usuario.service';
import { Router } from '@angular/router';

import swal from 'sweetalert2';

@Component({
  selector: 'app-modal-historia-usuario',
  templateUrl: './modal-historia-usuario.component.html'
})
export class ModalHistoriaUsuarioComponent implements DoCheck {

  n_vez = 0;
  n_vez2 = 0;

  estadoEliminar = 1

  historiaUsuario = {
    "ID_HISTORIA_USUARIO": "",
    "idUsuarioCreador": "",
    "idProyecto": 0,
    "titulo": "",
    "solicitante": "",
    "prioridad": "ALTA",
    "riesgo": "ALTA",
    "estimacion": "1",
    "orden": "1",
    "estado": "1",
    "descripcion": "",
    "condicion": "",
    "restriccion": "",
    "token": ""
  }

  historiaUsuarioERROR = {
    "titulo": "",
    "solicitante": "",
    "prioridad": "",
    "riesgo": "",
    "estimacion": "",
    "orden": "",
    "estado": "",
    "descripcion": "",
    "condicion": "",
    "restriccion": "",
  }

  usuarioActual = {
    "id": "",
    "rol": "",
    "id_usuario": "",
    "id_proyecto": "",
    "estado": ""
  };

  constructor(
    public __ModalHistoriaUsuarioService: ModalHistoriaUsuarioService,
    public __historiaUsuarioService: HistoriaUsuarioService,
    public _modalCargando: CargandoService,
    public __usuarioService: UsuarioService,
    public _usuarioService: UsuarioService,
    public _rolesProyecto: RolesProyectoService,
    public router: Router,
  ) { }

  ngDoCheck() {

    if (this.__ModalHistoriaUsuarioService.oculto == "" && this.n_vez == 0) {
      this.n_vez = 1;
      this.getRolEnProyecto(this.__ModalHistoriaUsuarioService.idProyecto);
    }

    if (this.__ModalHistoriaUsuarioService.oculto == "" && this.n_vez2 == 0 && this.__ModalHistoriaUsuarioService.type == "update") {
      this.n_vez2 = 1;

      this.historiaUsuario.ID_HISTORIA_USUARIO = this.__ModalHistoriaUsuarioService.hu.id;
      this.historiaUsuario.titulo = this.__ModalHistoriaUsuarioService.hu.titulo;
      this.historiaUsuario.solicitante = this.__ModalHistoriaUsuarioService.hu.solicitante;
      this.historiaUsuario.prioridad = this.__ModalHistoriaUsuarioService.hu.prioridad;
      this.historiaUsuario.riesgo = this.__ModalHistoriaUsuarioService.hu.riesgo;
      this.historiaUsuario.estimacion = this.__ModalHistoriaUsuarioService.hu.estimacion;
      this.historiaUsuario.orden = this.__ModalHistoriaUsuarioService.hu.orden;
      this.historiaUsuario.estado = this.__ModalHistoriaUsuarioService.hu.estado;
      this.historiaUsuario.descripcion = this.__ModalHistoriaUsuarioService.hu.descripcion;
      this.historiaUsuario.condicion = this.__ModalHistoriaUsuarioService.hu.condicion;
      this.historiaUsuario.restriccion = this.__ModalHistoriaUsuarioService.hu.restriccion;

      this.getRolEnProyecto(this.__ModalHistoriaUsuarioService.idProyecto);
    }

  }

  cerrarModal() {
    this.n_vez = 0;
    this.n_vez2 = 0;

    this.estadoEliminar = 1;

    this.historiaUsuario = {
      "ID_HISTORIA_USUARIO": "",
      "idUsuarioCreador": "",
      "idProyecto": 0,
      "titulo": "",
      "solicitante": "",
      "prioridad": "ALTA",
      "riesgo": "ALTA",
      "estimacion": "1",
      "orden": "1",
      "estado": "1",
      "descripcion": "",
      "condicion": "",
      "restriccion": "",
      "token": ""
    }

    this.limpiar();


    this.__ModalHistoriaUsuarioService.ocultarModal();
  }

  getRolEnProyecto(idProyecto) {
    let data = {
      "idProyecto": idProyecto,
      "token": this._usuarioService.token
    }

    this._rolesProyecto.buscarPorProyectoYUsuario(data)
      .subscribe((resp2: any) => {
        if (resp2.data.length == 0) {
          this.router.navigate(["/dashboard"]);
        }

        this.usuarioActual = resp2.data[0];

      });
  }

  limpiar() {
    this.historiaUsuarioERROR = {
      "titulo": "",
      "solicitante": "",
      "prioridad": "",
      "riesgo": "",
      "estimacion": "",
      "orden": "",
      "estado": "",
      "descripcion": "",
      "condicion": "",
      "restriccion": "",
    }
  }

  eliminarHU() {
    let data_enviar = {
      "idHistoriaUsuario": this.__ModalHistoriaUsuarioService.hu.id,
      "token": this.__usuarioService.token
    }

    this.__historiaUsuarioService.getProductsBacklogOfHU(data_enviar)
      .subscribe((resp2: any) => {
        let respData = resp2.data;

        if (resp2.ok && respData.length > 0) {

          this.estadoEliminar = 0;

        } else if (resp2.ok) {


          this.__historiaUsuarioService.eliminarHU(data_enviar)
            .subscribe((resp3: any) => {
              this.estadoEliminar = 0;
              this.__historiaUsuarioService.__refreshHistoriaUsuario();
              this.cerrarModal();
              if (resp2.ok) {
                swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Esta historia de usuario ha sido removido de manera exitosa!',
                  showConfirmButton: false,
                  timer: 2500
                })
              }
            });

        }
      });


    // this.__historiaUsuarioService.eliminarHU(data_enviar)
    //             .subscribe((resp2:any) =>
    //               {
    //                 this.__historiaUsuarioService.__refreshHistoriaUsuario();
    //                 this.cerrarModal();
    //               });

  }

  validarCampo(campo) {
    if (campo == "estimacion") {
      if (parseInt(this.historiaUsuario.estimacion) > 100) {
        this.historiaUsuarioERROR.estimacion = "Este campo no puede tener un número mayor a 100";
        this.historiaUsuario.estimacion = "100"
        return;
      } else if (parseInt(this.historiaUsuario.estimacion) < 1) {
        this.historiaUsuarioERROR.estimacion = "Este campo no puede tener un número menor a 1";
        this.historiaUsuario.estimacion = "1"
        return;
      } else {
        this.limpiar();
      }

    }
  }

  agregarHU() {
    if (this.historiaUsuario.titulo == "") { this.historiaUsuarioERROR.titulo = "Este campo no puede estar vacio"; return; } this.limpiar();
    if (this.historiaUsuario.solicitante == "") { this.historiaUsuarioERROR.solicitante = "Este campo no puede estar vacio"; return; } this.limpiar();
    if (this.historiaUsuario.descripcion == "") { this.historiaUsuarioERROR.descripcion = "Este campo no puede estar vacio"; return; } this.limpiar();

    this.historiaUsuario.token = this.__usuarioService.token;
    this.historiaUsuario.idProyecto = this.__ModalHistoriaUsuarioService.idProyecto;

    this._modalCargando.mostrarAnimacion();
    this.__historiaUsuarioService.agregarHU(this.historiaUsuario)
      .subscribe((resp2: any) => {
        this._modalCargando.ocultarAnimacion();
        this.__historiaUsuarioService.__refreshHistoriaUsuario();
        this.cerrarModal();
      });

  }

  actualizarHU() {
    if (this.historiaUsuario.titulo == "") { this.historiaUsuarioERROR.titulo = "Este campo no puede estar vacio"; return; } this.limpiar();
    if (this.historiaUsuario.solicitante == "") { this.historiaUsuarioERROR.solicitante = "Este campo no puede estar vacio"; return; } this.limpiar();
    if (this.historiaUsuario.descripcion == "") { this.historiaUsuarioERROR.descripcion = "Este campo no puede estar vacio"; return; } this.limpiar();

    this.historiaUsuario.token = this.__usuarioService.token;
    this.historiaUsuario.idProyecto = this.__ModalHistoriaUsuarioService.idProyecto;

    this._modalCargando.mostrarAnimacion();
    this.__historiaUsuarioService.actualizarHU(this.historiaUsuario)
      .subscribe((resp2: any) => {
        this._modalCargando.ocultarAnimacion();
        if (resp2.ok) {
          this.__historiaUsuarioService.__refreshHistoriaUsuario();
          this.cerrarModal();
        }

      });
  }

}
