<div class="fondo-negro animated fadeIn" [ngClass]="_VistaImagenService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content" style="overflow: auto;height: auto;max-height: 660px;">
            <div class="modal-header">
              <h2 class="modal-title">IMG</h2>
              
              <button (click)="_VistaImagenService.ocultarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
                <span aria-hidden="true" >&times;</span>
              </button>
            </div>
            
            <div class="modal-body" align="center">
                
                <img *ngIf="_VistaImagenService.urlImagen!==''" class="w-100" src="{{_VistaImagenService.urlImagen}}" alt="">
            </div>
            
          </div>
        </div>
    </div>
  </div>