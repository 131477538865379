<div class="fondo-negro animated fadeIn" [ngClass]="_participantesService.oculto">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" style="overflow: auto;height: 660px;">
        <div class="modal-header">
          <h2 class="modal-title">Agregar usuarios a la sala</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body" align="center">

          <!-- <div class="row">
                
                <div class="col-xl-4 col-lg-6 col-12" *ngFor="let uR of usuarioRolesData">
                  <div class="card">
                    <div class="card-content">
                      
                      <div class="card-body">
                        <i class="ft-user success font-large-2"></i>
                        <p class="card-text">{{ uR.email}} </p>
                        <p class="card-text">{{ uR.rolEnProyecto}} </p>
                        <button type="button" *ngIf="uR.rolEnProyecto!='PRODUCT_OWNER'" (click)="eliminarUsuarioDelProyecto(uR)" class="btn btn-danger">Eliminar</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div> -->

          <div class="input-group">
            <input type="text" class="form-control" (keyup)="buscarUsuarioParaSala(usuarioBuscar)"
              [(ngModel)]="usuarioBuscar" placeholder="Busca el usuario" aria-describedby="button-addon2">
            <div class="input-group-append">

            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table class="table table-striped table-bordered javascript-sourced dataTable no-footer"
                id="DataTables_Table_2" role="grid" aria-describedby="DataTables_Table_2_info" style="">
                <thead>
                  <tr role="row">
                    <th class="sorting_asc" style="width: 176.75px;">Email</th>
                    <th class="sorting" style="width: 102.75px;"></th>
                  </tr>
                </thead>
                <tbody>

                  <ng-container *ngFor="let usu of participantes">
                    <tr role="row" class="odd">
                      <td>{{usu.email}}</td>
                      <td>
                        <button (click)="agregarParticipante(usu)" class="btn btn-success btn-raised">Agregar</button>
                      </td>
                    </tr>
                  </ng-container>



                </tbody>
              </table>
            </div>
          </div>





        </div>
        <div class="modal-footer">
          <button (click)="cerrarModal()" type="button" class="btn btn-danger">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>