import { Component, DoCheck } from '@angular/core';
import { ModalParticipanteService } from './modal-participante.service';
import { UsuarioService } from 'src/app/services/service.index';
import { SprintPlanningMeetingSocketService } from 'src/app/services/sprint-planning-meeting-socket.service';

@Component({
  selector: 'app-modal-participante',
  templateUrl: './modal-participante.component.html'
})
export class ModalParticipanteComponent implements DoCheck {

  usuarioBuscar = ''

  n_vez = 0
  idProyecto = 0

  participantes = []

  constructor(
    public _participantesService: ModalParticipanteService,
    public _usuarioService: UsuarioService,
    public _SprintPlanningMeetingSocketService: SprintPlanningMeetingSocketService,
  ) { }

  ngDoCheck() {

    if (this._participantesService.oculto == "" && this._participantesService.idProyecto != 0 && this.n_vez == 0) {
      this.n_vez = 1;
      this.idProyecto = this._participantesService.idProyecto;
      // this.getRolesEsteProyecto(this._gestionRolesService.idProyecto);
      // this.existeScrumMaster();
    }
  }

  buscarUsuarioParaSala(busqueda) {
    let dataEnviar = {
      "token": this._usuarioService.token,
      "idProyecto": this.idProyecto,
      "busqueda": busqueda,
    }

    this._SprintPlanningMeetingSocketService.buscarUsuarioParaSala(dataEnviar)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.participantes = respuesta.data;
          return;
        }

        this.participantes = [];

      });
  }

  agregarParticipante(usu) {

    let data_enviar = {
      "idUsuario": usu.idUsuario,
      "idProyecto": this.idProyecto,
      "token": this._usuarioService.token
    }

    this._SprintPlanningMeetingSocketService.agregarParticipante(data_enviar)
      .subscribe((respuesta2: any) => {
        this.buscarUsuarioParaSala("");
      });
  }

  cerrarModal() {

    this.participantes = [];
    this.n_vez = 0
    this.idProyecto = 0

    this._SprintPlanningMeetingSocketService.__refreshParticipantes();

    this._participantesService.ocultarModal();
  }

}
